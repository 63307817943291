// import {
//     getCompanyCookie,
//     setOnBoardCookie,
//     removeOnBoardCookie,
//   } from "auth/userCookies";
import { useContext } from "react";
import { getOnBoardCookie, removeCompanyCookie, removeCompanyUserCookie, removeOnBoardCookie, removeToken, setOnBoardCookie, setToken } from "../auth/cookies";
import firebase from "../auth/firebaseConfig"
import useFirebaseAuth from "../auth/useFirebaseAuth";
import { toastError, toastInfo } from "./Toasters";
import { useNavigate } from "react-router";
import { StateContext } from "../Context/StateContext";

const BASE_URL = process.env.REACT_APP_BASE_URL

function handleLogout() {
  removeCompanyCookie();
  removeCompanyUserCookie();
  removeToken();
  removeOnBoardCookie();
  // signOut();

  toastInfo("User logged out!");
  window.location.href = "/"
}
function handleSubscribeNow(subscriptionStatus) {
  // setSubsciptionStatus("need_subscription")  // subscription status needed here from backend
  toastInfo("Subscription required to proceed further!");
  window.location.href = "/subscribe-now"
}

export async function tokenValidator() {
  const token = await getOnBoardCookie();
  if (!token) {
    return null;
  }

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  const user = parseJwt(token);
  if (user.exp * 1000 < Date.now()) {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(async function (currentUser) {
        if (currentUser) {
          try {
            let newToken = await firebase.auth().currentUser.getIdToken(true);
            removeToken();
            removeOnBoardCookie();
            setToken(newToken);
            setOnBoardCookie(newToken)

            resolve(newToken);
          } catch (err) {
            console.log(err);
            reject(err);
          }
        } else {
          resolve(null);
        }
      });
    });
  } else {
    return token;
  }
}

export async function getMethod(url) {
  const token = await tokenValidator();
  if (token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status === true) {
        return data;
      }
      else {
        // toastError(data.message);
        if (data.code === 426) {

          // console.log("HIT 426 ERROR!")
          //  handleSubscribeNow()
          // console.log("DATA IN HIT 426 : ", data)
            return response;
            // removeOnBoardCookie();
        }else{

          toastError(data.message)

          return response;
        }
      }
    } catch (e) {
      toastError(e.message);
      console.log("ERROR MESSAGE: ", e.message)
      return e.message;
    }
  } else {
    return false;
  }
}
export async function getMethodTokenless(url) {

  try {
    const response = await fetch(BASE_URL + url);
    const data = await response.json();
    if (data.status === true) {
      return data;
    } else {
      //   toastError(data.message);
      console.log("DATA MESSAGE: ", data.message)
      if (data.code === 426) {
        setTimeout(() => {
          handleSubscribeNow()
          return response;
        }, 2500);
      }
      return response;
    }
  } catch (e) {
    // toastError(e.message);
    console.log("ERROR MESSAGE: ", e.message)
    return e.message;
  }

}

export async function postMethod(url, payload) {

  const token = await tokenValidator();
  if (token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json")
    // type !== "formData" && myHeaders.append("Content-Type", "application/json");

    // var raw;
    // type === "formData" ? (raw = payload) : (raw = JSON.stringify(payload));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
      redirect: "follow",
    };

    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();

      if (data.status === true) {
        return data;
      } else {
        toastError(data.message);
        if (data.code === 403) {
          setTimeout(() => {
            handleLogout();
            return response;
          }, 2500);
        }
        else if (data.code === 426) {
          setTimeout(() => {
            handleSubscribeNow()
            return response;
          }, 2500);
        }
        return response;
      }
    } catch (e) {
      toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }
}

export async function postMethodTokenless(url, payload, token) {

  var myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json")
  if (token !== undefined && token !== null && token) {
    myHeaders.append("Authorization", "Bearer " + token)
  }
  // type !== "formData" && myHeaders.append("Content-Type", "application/json");

  // var raw;
  // type === "formData" ? (raw = payload) : (raw = JSON.stringify(payload));

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: payload,
    redirect: "follow",
  };

  try {
    const response = await fetch(BASE_URL + url, requestOptions);
    const data = await response.json();

    if (data.status === true) {
      return data;
    } else {
      toastError(data.message);
      console.log(data.message)
      if (data.code === 426) {
        setTimeout(() => {
          handleSubscribeNow()
          return response;
        }, 2500);
      }
      else{
       return response;
      }
    }
  } catch (e) {
    toastError(e.message);
    return e.message;
  }

}

export async function patchMethod(url, payload, type) {

  const token = await tokenValidator();
  if (token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(payload);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status === true) {
        return data;
      } else {
        toastError(data.message);
        console.log(data.message)
        if (data.code === 426) {
          setTimeout(() => {
            handleSubscribeNow()
            return response;
          }, 2500);
        }
        return response;
      }
    } catch (e) {
      toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }
}

export async function patchFormMethod(url, formdata) {

  const token = await tokenValidator();

  if (token) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status === true) {
        return data;
      } else {
        toastError(data.message);
        if (data?.code === 403) {
          setTimeout(() => {
            handleLogout();
            return response;
          }, 2500);
        }
        else if (data.code === 426) {
          setTimeout(() => {
            handleSubscribeNow()
            return response;
          }, 2500);
        }
        else {
          return response;
        }
      }
    } catch (e) {
      toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }

}
export async function postFormMethod(url, formdata) {

  const token = await tokenValidator();

  if (token) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data?.status === true) {
        return data;
      }
      else {
        toastError(data?.message);
        if (data?.code === 403) {
          setTimeout(() => {
            handleLogout();
            return response;
          }, 2500);
        }
        else if (data.code === 426) {
          setTimeout(() => {
            handleSubscribeNow()
            return response;
          }, 2500);
        }
        else {

          // toastError(data.message);
          return response;
        }
      }

    } catch (e) {
      // if(e.code == 403){
      // console.log("403 error occured")
      // }
      toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }

}

export async function deleteMethod(url) {
  const token = await tokenValidator();
  if (token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status === true) {
        return data;
      } else {
        // toastError(data.message);
        if (data.code === 426) {
          setTimeout(() => {
            handleSubscribeNow()
            return response;
          }, 2500);
        }
        return data.message;
      }
    } catch (e) {
      // toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }
}