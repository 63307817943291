import { useContext, useEffect, useState } from "react";
import InputField from "../../general/NewInputField";
import { getMethod, postFormMethod } from "../../../utils/apiMethod";
import { StateContext } from "../../../Context/StateContext";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import ImageUpload from "../../general/ImageUpload";

function PeopleEdit({ onBackClick, peopleId }) {
  const [peopleTypeId, setPeopleTypeId] = useState(null);
  const [peopleGetData, setPeopleGetData] = useState(null);

  const {setLoading } = useContext(StateContext);

  // FOUNDERS : state vaiables , functions

  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [bio, setBio] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // EXECUTIVES : state vaiables , functions
  const [executiveName, setExecutiveName] = useState("");
  const [executiveTitle, setExecutiveTitle] = useState("");
  const [executiveLinkedInUrl, setExecutiveLinkedInUrl] = useState("");
  const [selectedExecutiveFile, setSelectedExecutiveFile] = useState(null);

  const handleExecutiveFileChange = (event) => {
    setSelectedExecutiveFile(event.target.files[0]);
  };
  // INVESTORS : state vaiables , functions
  const [investorName, setInvestorName] = useState("");
  const [selectedInvestorFile, setSelectedInvestorFile] = useState(null);

  const handleInvestorsFileChange = (event) => {
    setSelectedInvestorFile(event.target.files[0]);
  };

  // EXECUTIVES : state vaiables , functions
  const [memberName, setMemberName] = useState("");
  const [memberTitle, setMemberTitle] = useState("");
  const [memberLinkedInUrl, setMemberLinkedInUrl] = useState("");
  const [selectedMemberFile, setSelectedMemberFile] = useState(null);

  const handleMembersFileChange = (event) => {
    setSelectedMemberFile(event.target.files[0]);
  };

  // Common functions

  function validateUrl(url, type){

    try{

      let validate = new URL(url)
      const isLinkedIn = validate.hostname.includes('linkedin.com') && validate.pathname.startsWith('/in/');
    
    if (!isLinkedIn) {
      throw new Error();
    }

      return true

    }catch(error){
      throw new Error(`${type} LinkedIn url not valid!`)
    }


  }

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    try{

      var formdata = new FormData();
    formdata.append("peopleId", peopleGetData.id);
    if (peopleTypeId === 4) {
      if (peopleGetData.name !== name) {
        formdata.append("name", name);
      }
      if (peopleGetData.title !== title) {
        formdata.append("title", title);
      }
      // if (peopleGetData.bio !== bio) {
      //   formdata.append("bio", bio);
      // }
      if (peopleGetData.linkedinUrl !== linkedInUrl) {
        validateUrl(linkedInUrl, "Advisor");
        formdata.append("linkedinUrl", linkedInUrl);
      }
      if (peopleGetData.imageUrl !== selectedFile) {
        formdata.append("profileImage", selectedFile);
      }
    } else {
      if (peopleTypeId === 1) {
        if (peopleGetData.name !== executiveName) {
          formdata.append("name", executiveName);
        }
        if (peopleGetData.title !== executiveTitle) {
          formdata.append("title", executiveTitle);
        }
        if (peopleGetData.linkedinUrl !== executiveLinkedInUrl) {
          validateUrl(executiveLinkedInUrl, "Executive");
          formdata.append("linkedinUrl", executiveLinkedInUrl);
        }
        if (peopleGetData.imageUrl !== selectedExecutiveFile) {
          formdata.append("profileImage", selectedExecutiveFile);
        }
      }
      if (peopleTypeId === 2) {
        if (peopleGetData.name !== investorName) {
          formdata.append("name", investorName);
        }
        if (peopleGetData.imageUrl !== selectedInvestorFile) {
          formdata.append("profileImage", selectedInvestorFile);
        }
      }
      if (peopleTypeId === 3) {
        if (peopleGetData.name !== memberName) {
          formdata.append("name", memberName);
        }
        if (peopleGetData.title !== memberTitle) {
          formdata.append("title", memberTitle);
        }
        if (peopleGetData.linkedinUrl !== memberLinkedInUrl) {
          validateUrl(memberLinkedInUrl, "Board Member");
          formdata.append("linkedinUrl", memberLinkedInUrl);
        }
        if (peopleGetData.imageUrl !== selectedMemberFile) {
          formdata.append("profileImage", selectedMemberFile);
        }
      }
    }

    postFormMethod(`people/updatePeople`, formdata)
      .then((res) => {
        setLoading(false);
        if (res?.status === true) {
          toastSuccess(res?.message);
          onBackClick()
          
        }else{
        toastError(res?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error?.message);
      });

    }catch(error){
      setLoading(false)
      toastError(error?.message)
    }

    
  };

  useEffect(() => {
    setLoading(true);
   
      window.scrollTo(0,0)
  
    getMethod(`people/viewPeople/${peopleId}`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          console.log("RESPONSE FROM EDIT: ", res.data);
          setPeopleTypeId(res?.data?.peopleTypeId);
          setPeopleGetData(res.data);

          if (res.data?.peopleTypeId === 4) {
            setName(res.data.name);
            setTitle(res.data.title);
            setLinkedInUrl(res.data.linkedinUrl);
            // setBio(res.data.bio);
            setSelectedFile(res.data.imageUrl);
          } else {
            if (res.data?.peopleTypeId === 1) {
              setExecutiveName(res.data.name);
              setExecutiveTitle(res.data.title);
              setExecutiveLinkedInUrl(res.data.linkedinUrl);
              setSelectedExecutiveFile(res.data.imageUrl);
            }
            if (res.data?.peopleTypeId === 2) {
              setInvestorName(res.data.name);
              setSelectedInvestorFile(res.data.imageUrl);
            }
            if (res.data?.peopleTypeId === 3) {
              setMemberName(res.data.name);
              setMemberTitle(res.data.title);
              setMemberLinkedInUrl(res.data.linkedinUrl);

              setSelectedMemberFile(res.data.imageUrl);
            }
          }

          // set product name, description and image
        }
        if (res.status === 402) {
          //   router.push("/Subscription");
          console.log("res.status = 402");
        }
      })
      .catch((error) => {
        setLoading(false);
        // toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  return (
    <form className="p-5 lg:p-8 category-content-wrapper-div" onSubmit={submitHandler}>
      {/*  Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={onBackClick} className="cursor-pointer">
              <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px]">Edit people</div>
              <div className="text-grey-2">Edit people details</div>
            </div>
          </div>

          <div className="flex lg:justify-end mt-5 lg:mt-0">
            <button className="btn-sea-green-thick" type="submit">
              Save
            </button>
          </div>
        </div>
      </div>

      {/* Form */}

      {/* FORM FIELDS ACCORDING TO SELECTED OPTION */}

      {/* 1) Founders */}

      {peopleTypeId === 4 ? (
        <div className="form-outline-wrapper-div p-8 gap-8">
          {/* <div className="flex flex-col gap-4">
            <div>
              Founder Pic:<span className="text-orange-1">*</span>
            </div>
            <label
              htmlFor="fileInput"
              className="flex items-center rounded-2xl bg-light-sea-green-1 h-28 w-28"
            >
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                className="hidden"
                onChange={handleFileChange}
              />
              <div className="flex items-center m-auto rounded-2xl h-full w-full">
                {selectedFile ? (
                  <img
                    src={
                      selectedFile instanceof File
                        ? URL.createObjectURL(selectedFile)
                        : selectedFile
                    }
                    alt="upload"
                    className="m-auto rounded-2xl h-full w-full"
                  />
                ) : (
                  <img
                    src="assets/documentupload.svg"
                    alt="upload"
                    className="m-auto"
                  />
                )}
              </div>
            </label>
          </div> */}

          <ImageUpload
            thumbnail={selectedFile}
            setThumbnail={setSelectedFile}
            label={"Advisor's Pic"}
            required={true}
          />

          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <InputField
              label="Advisor name"
              placeholder="Advisor name"
              value={name ? name : ""}
              onChange={(e) => setName(e.target.value)}
              width={"w-full"}
              optional={false}
            />
            <InputField
              label="Title"
              placeholder="Add title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              width={"w-full"}
              optional={false}
            />
            <InputField
              label="LinkedIn Url"
              placeholder="Add linkedin profile url"
              value={linkedInUrl}
              onChange={(e) => setLinkedInUrl(e.target.value)}
              width={"w-full"}
              optional={false}
            />
          </div>

          {/* <div className={`flex flex-col gap-4 w-full`}>
            <div>
              Bio<span className="text-orange-1">*</span>
              <span className="text-grey-12">&nbsp;( max 100 words )</span>
            </div>

            <div className="flex flex-col rounded-lg border border-grey-7 p-3 gap-2 h-20">
              <textarea
                className="focus:border-transparent outline-none w-full"
                placeholder="Enter bio"
                style={{ resize: "none" }}
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              ></textarea>
            </div>
          </div> */}
        </div>
      ) : peopleTypeId === 1 ? (
        <div className="form-outline-wrapper-div p-8 gap-8">
          {/* Logo input */}

          {/* <div className="flex flex-col gap-4">
            <div>
              Executive's Pic:<span className="text-orange-1">*</span>
            </div>
            <label
              htmlFor="fileInput"
              className="flex items-center rounded-2xl bg-light-sea-green-1 h-28 w-28"
            >
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                className="hidden"
                onChange={handleExecutiveFileChange}
              />
              <div className="flex items-center m-auto rounded-2xl h-full w-full">
                {selectedExecutiveFile ? (
                  <img
                    src={
                      selectedExecutiveFile instanceof File
                        ? URL.createObjectURL(selectedExecutiveFile)
                        : selectedExecutiveFile
                    }
                    alt="upload"
                    className="m-auto rounded-2xl h-full w-full"
                  />
                ) : (
                  <img
                    src="assets/documentupload.svg"
                    alt="upload"
                    className="m-auto"
                  />
                )}
              </div>
            </label>
          </div> */}

          <ImageUpload
            thumbnail={selectedExecutiveFile}
            setThumbnail={setSelectedExecutiveFile}
            label={"Executive's Pic"}
            required={true}
          />

          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <InputField
              label="Executive name"
              placeholder="Executive name"
              value={executiveName}
              onChange={(e) => setExecutiveName(e.target.value)}
              width={"w-full"}
              optional={false}
            />
            <InputField
              label="Title"
              placeholder="Add title"
              value={executiveTitle}
              onChange={(e) => setExecutiveTitle(e.target.value)}
              width={"w-full"}
              optional={false}
            />
            <InputField
              label="LinkedIn Url"
              placeholder="Add linkedin profile url"
              value={executiveLinkedInUrl}
              onChange={(e) => setExecutiveLinkedInUrl(e.target.value)}
              width={"w-full"}
              optional={false}
            />
          </div>
        </div>
      ) : peopleTypeId === 2 ? (
        <div className="form-outline-wrapper-div p-8 gap-8">
          {/* <div className="flex flex-col gap-4">
            <div>
              Investor's Logo:<span className="text-orange-1">*</span>
            </div>
            <label
              htmlFor="fileInput"
              className="flex items-center rounded-2xl bg-light-sea-green-1 h-28 w-28"
            >
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                className="hidden"
                onChange={handleInvestorsFileChange}
              />
              <div className="flex items-center m-auto rounded-2xl h-full w-full">
                {selectedInvestorFile ? (
                  <img
                    src={
                      selectedInvestorFile instanceof File
                        ? URL.createObjectURL(selectedInvestorFile)
                        : selectedInvestorFile
                    }
                    alt="upload"
                    className="m-auto rounded-2xl h-full w-full"
                  />
                ) : (
                  <img
                    src="assets/documentupload.svg"
                    alt="upload"
                    className="m-auto"
                  />
                )}
              </div>
            </label>
          </div> */}

          <ImageUpload
            thumbnail={selectedInvestorFile}
            setThumbnail={setSelectedInvestorFile}
            label={"Investor's Logo"}
            required={true}
          />

          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <InputField
              label="Investor name"
              placeholder="Investor name"
              value={investorName}
              onChange={(e) => setInvestorName(e.target.value)}
              width={"w-full"}
              optional={false}
            />
          </div>
        </div>
      ) : peopleTypeId === 3 ? (
        <div className="form-outline-wrapper-div p-8 gap-8">
          {/* <div className="flex flex-col gap-4">
            <div>
              Member's Pic:<span className="text-orange-1">*</span>
            </div>
            <label
              htmlFor="fileInput"
              className="flex items-center rounded-2xl bg-light-sea-green-1 h-28 w-28"
            >
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                className="hidden"
                onChange={handleMembersFileChange}
              />
              <div className="flex items-center m-auto rounded-2xl h-full w-full">
                {selectedMemberFile ? (
                  <img
                    src={
                      selectedMemberFile instanceof File
                        ? URL.createObjectURL(selectedMemberFile)
                        : selectedMemberFile
                    }
                    alt="upload"
                    className="m-auto rounded-2xl h-full w-full"
                  />
                ) : (
                  <img
                    src="assets/documentupload.svg"
                    alt="upload"
                    className="m-auto"
                  />
                )}
              </div>
            </label>
          </div> */}

          <ImageUpload
            thumbnail={selectedMemberFile}
            setThumbnail={setSelectedMemberFile}
            label={"Member's Pic"}
            required={true}
          />

          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <InputField
              label="Member name"
              placeholder="Member name"
              value={memberName}
              onChange={(e) => setMemberName(e.target.value)}
              width={"w-full"}
              optional={false}
            />
            <InputField
              label="Title"
              placeholder="title"
              value={memberTitle}
              onChange={(e) => setMemberTitle(e.target.value)}
              width={"w-full"}
              optional={false}
            />
            <InputField
              label="LinkedIn Url"
              placeholder="LinkedIn Url"
              value={memberLinkedInUrl}
              onChange={(e) => setMemberLinkedInUrl(e.target.value)}
              width={"w-full"}
              optional={false}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {/* Save changes button */}

      <div className="flex flex-row-reverse">
        <div className="flex lg:justify-end w-full lg:w-2/12">
          <button
            className="btn-sea-green text-xl w-full"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
      {/* <ToastContainer/> */}
    </form>
  );
}

export default PeopleEdit;
