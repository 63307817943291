import React, { useState, useRef, useEffect, useContext } from "react";
import InputField from "../../general/NewInputField";
import { getCompanyCookie, setCompanyCookie } from "../../../auth/cookies";
import cookies from "js-cookie";
import {
  patchMethod,
  patchFormMethod,
  postMethod,
  getMethod,
  deleteMethod,
} from "../../../utils/apiMethod";
import { toastError, toastSuccess, toastWarn } from "../../../utils/Toasters";
import SelectMarketCategoriesModal from "../../general/SelectMarketCategories";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer } from "react-toastify";
import { MdEdit } from "react-icons/md";
import EditAddress from "../../general/modals/EditAddress";
import AddAddress from "../../general/modals/AddAddress";
import marketCategories from "./MarketCategories";
import MarketCategories from "./MarketCategories";
import { StateContext } from "../../../Context/StateContext";
import Tooltip from "../../general/Tooltip";
import ImageUpload from "../../general/ImageUpload";
import { FormatDate } from "../../../utils/dateFormat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"
import { useNavigate } from "react-router";

function CompanyProfileEdit({ onBackClick }) {
  const navigate = useNavigate();
  const { setLoading } = useContext(StateContext);
  const { setRefreshHeader } = useContext(StateContext);
  const { setCompanyName, setCompanyLogo, setCompanyBio } =
    useContext(StateContext);

  const [stepper, setStepper] = useState(0);
  const [categoryItems, setCategoryItems] = useState([]);

  const [existingCategoryIds, setExistingCategoryIds] = useState([]);

  // const [name, setName] = useState(cookie.companyName);
  // const [email, setEmail] = useState(cookie.cemail);
  // const [description, setDescription] = useState(cookie.cdescription === 'null'? '':cookie.cdescription);
  // const [phone, setPhone] = useState(cookie.cPhone);
  const [companyDetails, setCompanyDetails] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [meetingUrl, setMeetingUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const datePickerRef = useRef(null);

  const handleDateClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };

  //Market categoris variables
  const [marketCategoriesDetails, setMarketCategoriesDetails] = useState([]);
  const fetchSelectedOption = (marketCategory) => {
    // console.log(" option : ", marketCategory)
    setMarketCategoriesDetails(marketCategory);
  };

  // owner state variables
  const [ownerName, setOwnerName] = useState("");

  const [ownerEmail, setOwnerEmail] = useState("");
  const [ownerThumbnail, setOwnerThumbnail] = useState(null);

  // addresses from get-api
  const [addressData, setAddressData] = useState([]);

  const [addressForm, setAddressForm] = useState({
    address: "",
    zipCode: "",
    city: "",
    state: "",
  });
  // update address modal variables
  const [addressId, setAddressId] = useState("");
  const [openUpdateAddressModal, setOpenUpdateAddressModal] = useState(false);
  const [openAddAddressModal, setOpenAddAddressModal] = useState(false);
  const [refresher, setRefresher] = useState(false);

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click(); // Trigger the file input dialog
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setThumbnail(selectedFile);
    // Do something with the selected file
    console.log("Selected file:", selectedFile);
  };

  const fileOwnerInputRef = useRef(null);

  const handleOwnerClick = () => {
    fileOwnerInputRef.current.click(); // Trigger the file input dialog
  };

  const handleOwnerFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setOwnerThumbnail(selectedFile);
    // Do something with the selected file
    console.log("Selected file owner :", selectedFile);
  };

  // SAVE AND CONTINUE BUTTONS

  function validateUrl(url) {
    try {
      let validate = new URL(url);

      return true;
    } catch (error) {
      throw new Error(`Meeting URL not valid!`);
    }
  }

  const validatePhoneNumber = (number) => {
    console.log("NUMBER : ", number)
   

      if (number.length > 0) {
        if(number.length > 8){

          return true;
        }
       else {
        throw new Error("Enter a valid phone number atleast 7 digits long.");
      }}
      
  };

  const saveCompanyDetails = (e) => {
    e.preventDefault();
    setLoading(true);
    var formdata = new FormData();
    let flag = false;
    let isOwnerDetailsUpdated = false;
    var newDate;
    var isoFormat;
    console.log("SELECTED DATE : ", selectedDate)
    if(selectedDate){

       newDate = new Date(selectedDate)
       isoFormat = moment(newDate).format('YYYY-MM-DD');
    }
    else{
      isoFormat = ""
    }
    console.log("ISO : ", isoFormat)
    try {
      if (companyDetails?.company.meetingUrl !== meetingUrl) {
        const validateMeetingUrl = validateUrl(meetingUrl);
        formdata.append("meetingUrl", meetingUrl);
        flag = true;
      }

      if (companyDetails?.company?.name !== name) {
        formdata.append("name", name);
        flag = true;
      }

      if (companyDetails?.company?.companyFounded !== isoFormat) {

        formdata.append("companyFounded", isoFormat);
        flag = true;
      }
      if (companyDetails?.company?.description !== description) {
        formdata.append("description", description);
        flag = true;
      }
      if (companyDetails?.company.thumbnailUrl !== thumbnail) {
        formdata.append("thumbnail", thumbnail);
        flag = true;
      }
      // if (companyDetails?.company.meetingUrl !== meetingUrl) {
      // try{
      //   const isCorrect = new URL(meetingUrl);
      // console.log("Valid meeting URL:", isCorrect);

      // formdata.append("meetingUrl", meetingUrl);
      // flag = true;

      // }catch(error){
      //   flag = false
      //   console.log("ERROR : ", error)
      //   toastError(error.message)
      // }

      // }
      if (companyDetails?.company?.phone !== phone ) {
        validatePhoneNumber(phone);

        formdata.append("phone", phone);
        flag = true;
      }

      if (companyDetails?.company?.ownerName !== ownerName) {
        formdata.append("ownerName", ownerName);
        flag = true;
        isOwnerDetailsUpdated = true;
      }
      if (companyDetails?.company?.ownerEmail !== ownerEmail) {
        formdata.append("ownerEmail", ownerEmail);
        flag = true;
      }
      if (companyDetails?.company?.ownerImageUrl !== ownerThumbnail) {
        formdata.append("ownerImage", ownerThumbnail);
        flag = true;
        isOwnerDetailsUpdated = true;
      }

      flag
        ? patchFormMethod(`company/updateDetails`, formdata)
            .then((res) => {
              setLoading(false);

              if (res?.status == true) {
                console.log("RESPONSE : ", res?.data);
                setCompanyCookie(res?.data);
                // setCompanyLogo(res?.data?.thumbnailUrl);
                // setCompanyName(res?.data?.name);
                // setCompanyBio(res?.data?.description);
                if (isOwnerDetailsUpdated) {
                  setRefreshHeader((prev) => !prev);
                }
                toastSuccess(res?.message);
                setRefresher((prev) => !prev);
                setStepper(1);
              } else {
                toastError(res?.message);
              }
            })
            .catch((error) => {
              setLoading(false);
              toastError(error?.message);
              console.log("error.message: ", error?.message);
            })
        : setLoading(false) ;setStepper(1);
    } catch (error) {
      flag = false;
      setLoading(false);
      console.log("ERROR : ", error?.message);
      toastError(error.message);
    }
  };

  const deleteAddress = (id) => {
    deleteMethod(`company/deleteAddress/${id}`)
      .then((res) => {
        console.log("RESPONSE: ", res);
        if (res?.status == true) {
          //   setOpen(false);
          setAddressData(addressData?.filter((item) => item?.id != id));

          toastWarn(res?.message);
        } else {
          toastError(res?.message);
        }
      })
      .catch((error) => {
        toastError(error?.message);
        console.log("Error: ", error?.message);
      });
  };

  const handleUpdateAddress = (id) => {
    setAddressId(id);
    setOpenUpdateAddressModal(true);
  };

  const getMyCompanyDetailsApi = () => {
    setLoading(true);
    getMethod(`company/getMyCompanyDetails`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          if (res.company?.addresses.length > 0) {
            setAddressData(res.company?.addresses);
          }

          setName(res?.company?.company?.name);
          setEmail(res?.company?.company?.email);
          setPhone(
            res?.company?.company?.phone === null
              ? ""
              : res?.company?.company?.phone
          );
          setDescription(res?.company?.company?.description);
          setThumbnail(res?.company?.company?.thumbnailUrl);
          setMeetingUrl(res?.company?.company?.meetingUrl);
          setOwnerEmail(res?.company?.company?.ownerEmail);
          setOwnerName(res?.company?.company?.ownerName);
          setOwnerThumbnail(res?.company?.company?.ownerImageUrl);
          setSelectedDate(res?.company?.company?.companyFounded);

          const arr = res?.company?.company?.companyMarketCats?.map((item) => {
            return { ...item?.marketCategoryDefined, categoryId: item?.id };
          });

          setCategoryItems(arr);

          const existingCatsArr = res?.company?.company?.companyMarketCats?.map(
            (item) => {
              return item?.marketCategoryDefined?.id;
            }
          );

          setExistingCategoryIds(existingCatsArr);

          setMarketCategoriesDetails(res?.company?.company?.companyMarketCats);
          setCompanyDetails(res?.company);
        }
        if (res.status == 402) {
          setLoading(false);
          //   router.push("/Subscription");
          console.log("res.status = 402");
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  useEffect(() => {
    // setLoading(true);

    getMyCompanyDetailsApi();
  }, [refresher]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("SELECTED DATE : ", typeof(selectedDate))

  return (
    <div className="p-5 lg:p-8 category-content-wrapper-div">
      {/* Form */}

      <div className="flex flex-col gap-5">
        {/* COMPANY DETAILS */}

        {stepper === 0 && (
          <>
            {/*  Header */}
            <div className="flex flex-col gap-3">
              <div className="flex justify-between items-center">
                <div className="flex gap-3">
                  <div onClick={onBackClick} className="cursor-pointer">
                    <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
                  </div>

                  <div className="flex flex-col">
                    <div className="font-medium text-[28px]">
                      Edit Company Profile
                    </div>
                    <div className="text-grey-2">Add Your Company Details</div>
                  </div>
                </div>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                saveCompanyDetails(e);
              }}
            >
              <div className="form-outline-wrapper-div p-8 gap-5">
                <ImageUpload
                  thumbnail={thumbnail}
                  setThumbnail={setThumbnail}
                />

                {/* Input fields */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <InputField
                    label="Company Name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    width={"full"}
                  />

                  <div className={`flex flex-col gap-4 w-full `}>
                    <div className="font-light">Company Email</div>
                    <div className="flex items-center rounded-lg border border-grey-7 h-12 bg-grey-11 text-grey-10">
                      <input
                        className="focus:border-transparent bg-grey-5 text-grey-10 outline-none mx-5 w-full h-full  cursor-not-allowed"
                        placeholder={"Email"}
                        value={email}
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 w-full">
                    <label htmlFor="date">Company Founded:</label>

                    <div
                      className="flex items-center rounded-lg border border-grey-7 h-12 w-full"
                      // onClick={handleDateClick}
                    >
                      {/* <input
                        className="focus:border-transparent outline-none mx-5 w-full h-full"
                        type="date"
                        format=""
                        id="date"
                        value={selectedDate}
                        onChange={e => setSelectedDate(e.target.value)}
                        
                        /> */}

                      <DatePicker
                      showIcon
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        ref={datePickerRef}
                        dateFormat="MMMM d, yyyy"
                        maxDate={new Date()}
                   
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="focus:border-transparent outline-none mx-5 max-w-fit lg:w-full h-full"
   
          placeholderText="Select a date"
                      />

                     
                     
                    </div>
                  </div>

                  {/* <div className="flex flex-col gap-4 w-full">
      <label htmlFor="date">Company Founded:</label>

      <div className="flex items-center rounded-lg border border-grey-7 h-12 w-full">
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="MMMM d, yyyy"
          customInput={<CustomInput />}
          className="w-full"
        />
      </div>
    </div> */}

                  <InputField
                    label="Meeting URL"
                    placeholder="Meeting URL"
                    optional={true}
                    value={meetingUrl}
                    onChange={(e) => setMeetingUrl(e.target.value)}
                    width={"full"}
                  />

                  <div className="flex flex-col gap-4">
                    <div>Phone:</div>

                    <div>
                      <PhoneInput
                        country={"us"}
                        value={phone}
                        onChange={(phone, country, e, formattedValue) => {
                          setPhone(formattedValue);
                        }}
                        inputProps={{
                          // required: true,
                        }}
                        containerStyle={{
                          border: "1px solid #c0c0c0",
                          backgroundColor: "transparent",
                          padding: "5px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "8px",
                        }}
                        inputStyle={{
                          border: "none",
                          backgroundColor: "transparent",
                          width: "inherit",
                          borderRadius: "8px",
                        }}
                        buttonStyle={{
                          borderRight: "1px solid #c0c0c0",
                          borderLeft: "none",
                          borderBottom: "none",
                          borderTop: "none",
                          backgroundColor: "transparent",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className={`flex flex-col gap-4 w-full`}>
                  <div>
                    Description<span className="text-orange-1">*</span> &nbsp;
                    {/* <span className="text-grey-10 text-sm">{` ( ${description?.length} / 300 )`}</span> */}
                  </div>

                  <div className="flex flex-col rounded-lg border border-grey-7 p-3 gap-2 h-20">
                    <textarea
                      className="focus:border-transparent outline-none w-full"
                      placeholder="Enter description"
                      style={{ resize: "none" }}
                      value={description}
                      required={true}
                      onChange={(e) => setDescription(e.target.value)}
                      // maxLength={"300"}
                    ></textarea>
                  </div>
                </div>
              </div>

              {/* Owner Details */}

              <div className="form-outline-wrapper-div p-8 gap-8">
                {/* Logo input */}
                {/* <div className="flex flex-col gap-4">
                  <div>Profile Owner's Picture :</div>
                  <div className="flex items-center rounded-2xl bg-light-sea-green-1 h-28 w-28 cursor-pointer">
                    <div className="m-auto w-full h-full flex items-center justify-center">
                      {ownerThumbnail ? (
                        <img
                          src={
                            ownerThumbnail instanceof File
                              ? URL.createObjectURL(ownerThumbnail)
                              : ownerThumbnail
                          } // Create URL for the selected file
                          alt="Uploaded File"
                          style={{ cursor: "pointer" }}
                          className="object-cover h-full w-full rounded-2xl"
                          onClick={handleOwnerClick}
                        />
                      ) : (
                        <img
                          src="assets/documentupload.svg"
                          alt="upload"
                          className="m-auto"
                          onClick={handleOwnerClick}
                        />
                      )}
                      <input
                        type="file"
                        ref={fileOwnerInputRef}
                        style={{ display: "none" }} // Hide the file input
                        onChange={handleOwnerFileChange}
                        accept="image/*" // Accept only image files
                      />
                    </div>
                  </div>
                </div> */}

                <ImageUpload
                  thumbnail={ownerThumbnail}
                  setThumbnail={setOwnerThumbnail}
                />

                {/* Input fields */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <div className="flex flex-col gap-4 w-full">
                    <div className="font-light">
                      Profile Owner's Name{" "}
                      <span className="text-orange-1">*</span>
                    </div>
                    <div className="flex items-center rounded-lg border border-grey-7 h-12">
                      <input
                        className="focus:border-transparent outline-none mx-5 w-full h-full"
                        placeholder="Name"
                        value={ownerName}
                        required={true}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                            setOwnerName(inputValue);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <InputField
                    label="Profile Owner's Email"
                    placeholder="Email"
                    value={ownerEmail}
                    optional={true}
                    onChange={(e) => setOwnerEmail(e.target.value)}
                    width={"full"}
                    type={"email"}
                  />
                </div>
              </div>

              <div className="flex flex-wrap items-center justify-end gap-2 mt-5">
                <div className="flex justify-end w-full lg:w-3/12">
                  <button
                    className="btn-sea-green text-xl w-full"
                    type="submit"
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </form>
          </>
        )}

        {/* Address Details */}

        {stepper === 1 && (
          <div className="flex flex-col gap-8">
            {/*  Header */}
            <div className="flex flex-col gap-3">
              <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
                <div className="xs:flex-col lg:flex gap-3">
                  <div onClick={() => setStepper(0)} className="cursor-pointer">
                    <img src="/assets/arrow-back-circle.svg" alt="arrow-back" />
                  </div>

                  <div className="flex flex-col">
                    <div className="font-medium text-[28px]">
                      Edit Company Addresses
                    </div>

                    <div className="text-grey-2 mt-3 lg:mt-0">
                      Add Your Company Address Details
                    </div>
                  </div>
                </div>

                <div
                  className="mt-5 lg:mt-0"
                  role="button"
                  onClick={() => setOpenAddAddressModal(true)}
                >
                  <button className="btn btn-sea-green flex gap-2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 12H20M12 4V20"
                        stroke="#fff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Add New
                  </button>
                </div>
              </div>
            </div>

            {/* ADDRESS TABLE start */}

            {addressData?.length > 0 ? (
              <div className="flex flex-col gap-3 w-full overflow-x-scroll hide-scrollbar">
                {/* Headings */}

                <div className="grid grid-cols-address-cols gap-4 text-base font-semibold justify-between min-w-fit">
                  <div>Address</div>
                  <div>State</div>
                  <div>City</div>
                  <div>Zip Code</div>
                  <div>Actions</div>
                </div>

                {/* address rows */}

                {addressData.map((address, index) => {
                  return (
                    <div
                      key={index}
                      className="grid grid-cols-address-cols gap-4 border min-w-fit
                      border-grey-14 rounded-2xl p-3 text-grey-9 cursor-default hover:bg-grey-5 justify-between"
                    >
                      <Tooltip text={address?.address}>
                        <div className="line-clamp-1 cursor-pointer text-start w-full">
                          {address?.address}
                        </div>
                      </Tooltip>

                      <div className="capitalize">{address?.state}</div>

                      <div className="capitalize">{address?.city}</div>
                      <div className="capitalize">{address?.zipCode}</div>

                      <div className="flex row-reverse gap-2">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleUpdateAddress(address?.id)}
                        >
                          <MdEdit className="text-sea-green text-xl" />
                        </div>
                        {address?.label === "others" && (
                          <div
                            className="cursor-pointer"
                            onClick={() => deleteAddress(address?.id)}
                          >
                            <img src="/assets/cancel.svg" />
                          </div>
                        )}

                        {address?.label === "hq" && (
                          <span className="text-grey-7">HQ</span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex-col m-auto my-10 items-center justify-center">
                <img src="/assets/data-not-found.png" />
              </div>
            )}
            {/* ADDRESS TABLE end*/}

            <div className="flex flex-wrap items-center justify-end gap-2">
              <div className="flex justify-end mt-5 w-full lg:mt-0 lg:w-2/12">
                <button
                  className="btn-sea-green text-xl w-full"
                  onClick={() => setStepper(2)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        {/* MARKET CATEGORIES */}
        {stepper === 2 && (
          <>
            <MarketCategories
              existingCategories={existingCategoryIds}
              setStepper={() => setStepper(1)}
              redirectToMain={onBackClick}
            />
          </>
        )}
      </div>

      {/* <ToastContainer /> */}

      {openUpdateAddressModal && (
        <EditAddress
          addressId={addressId}
          setRefresher={setRefresher}
          onClickClose={() => setOpenUpdateAddressModal(false)}
        />
      )}
      {openAddAddressModal && (
        <AddAddress
          setRefresher={getMyCompanyDetailsApi}
          onClickClose={() => setOpenAddAddressModal(false)}
        />
      )}
    </div>
  );
}

export default CompanyProfileEdit;
