import React from 'react'
import Footer from '../general/NewFooter'
import { useNavigate } from 'react-router-dom'
import { toastInfo } from '../../utils/Toasters'

const Pricing = () => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col bg-white h-screen">
      {/* Header */}
      <div className="bg-white w-full">
        <div className="flex">
          <div className="w-1/4 bg-dark-blue-1 h-3"> </div>
          <div className="w-1/4 bg-aqua-1 h-3"> </div>
          <div className="w-1/4 bg-sea-green h-3"> </div>
          <div className="w-1/4 bg-yellow-1 h-3"> </div>
        </div>
        <div className="flex justify-center mt-3 mb-5">
          <div className="flex justify-center md:justify-between items-center w-10/12">
            <div className="">
              <img src="assets/FutrLogo.png" alt="" className="h-24" />
            </div>
          </div>
        </div>
      </div>

      {/* Section 1 : Power Up Sales Pipeline */}
      <div className='flex flex-col items-center gap-10 mt-10'>
        <div className='flex flex-col items-center gap-5 mb-20'>

          <div className='text-aqua-1 m-auto text-4xl md:text-[72px] font-extrabold w-full md:w-3/5 leading-tight tracking-wide text-center'>

            Power Up Your Sales Pipeline
            with futr Connect

          </div>

          <div className='text-grey-9 text-center text-lg w-5/6 md:w-2/5'>
            Ready to revolutionize your business development? futr Connect puts your tech solutions at the center of a thriving, self-service ecosystem built to drive engagement, leads, and growth. With our exclusive subscription package, you’ll be able to create a powerful presence that attracts the right customers at the right time, 24/7 - 365!
          </div>
        </div>

        <div className='bg-dark-blue-1 w-5/6 md:w-3/5 rounded-tr-[50px] md:rounded-tr-[100px] px-16 py-16 flex flex-col gap-5 items-center'>

          <div className='text-3xl md:text-5xl text-white'>
            Annual Subscription
          </div>

          <div className='text-md md:text-xl text-yellow-1'>
            1 Year Commitment
          </div>



        </div>




      </div>

      {/* Section 2 : white - What's Included */}

      <div className="bg-[linear-gradient(180deg,_#F5F5F5_0%,_#EEF9FB_94.91%)] pb-32">

        <div className='bg-white box-shadow5 w-5/6 md:w-3/5 flex flex-col items-center gap-10 py-10 px-5 md:px-20 m-auto relative rounded-bl-[50px] lg:rounded-bl-[100px]'>

          <div className='absolute top-0 m-auto'>
            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="30" viewBox="0 0 52 30" fill="none">
              <path d="M26 30L0.0192363 -1.0793e-06L51.9808 3.46332e-06L26 30Z" fill="#1B6283" />
            </svg>
          </div>

          <p className='text-aqua-1 text-lg lg:text-3xl font-medium text-start w-full'>
            <i>What's Included:</i>
          </p>

          <div className='flex flex-col gap-10 w-full'>


            <div className='flex gap-2 md:gap-8 items-center'>

              <div className='hidden md:block'>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                  <path d="M50.1599 7.92004H13.1999C10.2695 7.92004 7.91992 10.296 7.91992 13.2V50.16C7.91992 53.064 10.2695 55.44 13.1999 55.44H50.1599C53.0903 55.44 55.4399 53.064 55.4399 50.16V13.2C55.4399 10.296 53.0903 7.92004 50.1599 7.92004ZM26.3999 44.88L13.1999 31.68L16.9223 27.9576L26.3999 37.4088L46.4375 17.3712L50.1599 21.12L26.3999 44.88Z" fill="#2196F3" />
                </svg>
              </div>

              <div className='flex flex-col gap-3 w-full md:w-4/5'>

                <div className='text-dark-blue-1 font-semibold text-xl md:text-3xl'>
                  <i>Full Control of Your Profile</i>
                </div>

                <div className='text:md md:text-xl'>
                  Take charge of your brand. Don’t let a Google search tell your story. Customize your profile to showcase what makes your company unique and update it anytime with the latest on your company and tech solutions.
                </div>

              </div>

            </div>
            <div className='flex gap-8 items-center'>

              <div className='hidden md:block'>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                  <path d="M50.1599 7.92004H13.1999C10.2695 7.92004 7.91992 10.296 7.91992 13.2V50.16C7.91992 53.064 10.2695 55.44 13.1999 55.44H50.1599C53.0903 55.44 55.4399 53.064 55.4399 50.16V13.2C55.4399 10.296 53.0903 7.92004 50.1599 7.92004ZM26.3999 44.88L13.1999 31.68L16.9223 27.9576L26.3999 37.4088L46.4375 17.3712L50.1599 21.12L26.3999 44.88Z" fill="#2196F3" />
                </svg>
              </div>

              <div className='flex flex-col gap-3  w-full md:w-4/5'>

                <div className='text-dark-blue-1 font-semibold text-xl md:text-3xl'>
                  <i>Engage with Followers</i>
                </div>

                <div className='text:md md:text-xl'>
                  Connect with interested users who can follow your company for real-time updates, keeping them engaged with every innovation and achievement.
                </div>

              </div>

            </div>
            <div className='flex gap-8 items-center'>

              <div className='hidden md:block'>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                  <path d="M50.1599 7.92004H13.1999C10.2695 7.92004 7.91992 10.296 7.91992 13.2V50.16C7.91992 53.064 10.2695 55.44 13.1999 55.44H50.1599C53.0903 55.44 55.4399 53.064 55.4399 50.16V13.2C55.4399 10.296 53.0903 7.92004 50.1599 7.92004ZM26.3999 44.88L13.1999 31.68L16.9223 27.9576L26.3999 37.4088L46.4375 17.3712L50.1599 21.12L26.3999 44.88Z" fill="#2196F3" />
                </svg>
              </div>

              <div className='flex flex-col gap-3  w-full md:w-4/5'>

                <div className='text-dark-blue-1 font-semibold text-xl md:text-3xl'>
                  <i>Direct Meeting Scheduling</i>
                </div>

                <div className='text:md md:text-xl'>
                  Cut through the noise. Allow potential clients to schedule meetings directly with your team, streamlining the path to connection.
                </div>

              </div>

            </div>
            <div className='flex gap-8 items-center'>

              <div className='hidden md:block'>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                  <path d="M50.1599 7.92004H13.1999C10.2695 7.92004 7.91992 10.296 7.91992 13.2V50.16C7.91992 53.064 10.2695 55.44 13.1999 55.44H50.1599C53.0903 55.44 55.4399 53.064 55.4399 50.16V13.2C55.4399 10.296 53.0903 7.92004 50.1599 7.92004ZM26.3999 44.88L13.1999 31.68L16.9223 27.9576L26.3999 37.4088L46.4375 17.3712L50.1599 21.12L26.3999 44.88Z" fill="#2196F3" />
                </svg>
              </div>

              <div className='flex flex-col gap-3  w-full md:w-4/5'>

                <div className='text-dark-blue-1 font-semibold text-xl md:text-3xl'>
                  <i>Customer Feedback</i>
                </div>

                <div className='text:md md:text-xl'>
                  Get instant, actionable feedback from users to refine and enhance your offering.
                </div>

              </div>

            </div>
            <div className='flex gap-8 items-center'>

              <div className='hidden md:block'>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                  <path d="M50.1599 7.92004H13.1999C10.2695 7.92004 7.91992 10.296 7.91992 13.2V50.16C7.91992 53.064 10.2695 55.44 13.1999 55.44H50.1599C53.0903 55.44 55.4399 53.064 55.4399 50.16V13.2C55.4399 10.296 53.0903 7.92004 50.1599 7.92004ZM26.3999 44.88L13.1999 31.68L16.9223 27.9576L26.3999 37.4088L46.4375 17.3712L50.1599 21.12L26.3999 44.88Z" fill="#2196F3" />
                </svg>
              </div>

              <div className='flex flex-col gap-3 w-full md:w-4/5'>

                <div className='text-dark-blue-1 font-semibold text-xl md:text-3xl'>
                  <i>Email Notifications & Analytics</i>
                </div>

                <div className='text:md md:text-xl'>
                  Stay in the loop. Receive regular notifications on profile engagement and access detailed analytics to track your success and optimize your strategy.
                </div>

              </div>

            </div>

          </div>



          {/* Pricing */}

          <div className='flex flex-col md:flex-row justify-center border-t border-t-sea-green p-5 w-full'>

            <div className='flex gap-3 items-center w-full border-b-4 border-b-sea-green lg:border-b-0 lg:border-r-4 lg:border-r-sea-green relative overflow-clip'>

              <div className='w-[210px] h-[230px] md:h-[210px] rotate-[40deg] bg-sea-green absolute -left-40 bottom-3 flex justify-end items-start flex-shrink-0'>

                <div className='text-white -rotate-[40deg] flex flex-col items-center justify-center pt-10 pr-5'>

                  <div className='text-lg font-semibold'>
                    17%
                  </div>
                  <div className='text-sm font-semibold'>

                    Savings
                  </div>

                </div>

              </div>

              <div className='flex flex-col gap-3 ms-auto lg:items-center lg:m-auto pb-3 lg:pb-0'>

                <div className='text-sea-green text-lg'>
                  <i>Billed yearly</i>
                </div>

                <div className='text-3xl'>
                  $25,000
                </div>

                <div className='flex flex-col'>
                  <input id="yearly" name="billType" type='radio' className='m-auto' />
                  <label className='text-xl text-sea-green font-semibold m-auto lg:m-0' for="yearly">Select</label>
                </div>
              </div>


            </div>
            <div className='flex flex-col gap-3 ms-auto items-end lg:items-center w-full  lg:m-auto'>

              <div className='text-sea-green text-lg'>
                <i>Billed monthly</i>
              </div>

              <div className='text-3xl'>
                $2,500/mo
              </div>

              <div className='flex flex-col'>
                <input id="yearly" name="billType" type='radio' className='m-auto' />
                <label className='text-xl text-sea-green font-semibold m-auto lg:m-0' for="yearly">Select</label>
              </div>


            </div>

          </div>


          <div className="w-full sm:w-3/5 sm:m-auto">
            <button
              className="btn bg-aqua-1 rounded-full p-3 md:p-5 text-white w-full font-semibold"
              onClick={() => {toastInfo("Login to subscribe!"); navigate("/")}}
            >
             Subscribe Now
            </button>
          </div>

        </div>

      </div>

      {/* Section 3 : aqua-blue : Need Help? */}

      <div className='bg-[#58CAEB] flex flex-col md:flex-row p-10 pb-40'>

        {/* Text */}

        <div className='flex flex-col text-white gap-10 md:pl-40 md:pt-20 md:w-2/5'>

          <div className='flex flex-col gap-5'>

            <div className='text-3xl'>
              Need Help?
            </div>
            <div className='text-5xl'>
              We're Here for You
            </div>

          </div>

          <div className='text-lg'>
            Questions about your subscription? Need assistance?
          </div>

          <div className='text-lg flex flex-col gap-5'>
            <div>
              <span className='font-semibold'>General Support: </span><span className='text-dark-blue-1 underline'> help@futrconnect.com</span>
            </div>
            <div className='text-lg'>
              <span className='font-semibold'>Marketing inquiries :  </span><span className='text-xl'> Want to expand your reach?</span>
            </div>
            <div className='text-lg'>
              <span className='text-dark-blue-1 underline'>sales@futrconnect.com</span>
            </div>
          </div>
        </div>

        {/* Image */}

        <div>
          <img src="/assets/NeedHelp.png" />
        </div>

      </div>

      {/* Section 4 : white : blank */}

      <div className='flex relative justify-center p-20'>

        <div className='absolute -top-36 bg-yellow-1 rounded-[20px] md:rounded-[35px] m-auto flex flex-col gap-5 md:gap-0 md:flex-row w-10/12 p-8 md:p-16'>

          <div className='flex flex-col gap-5'>

            <div className='text-dark-blue-1 text-2xl md::text-5xl'>
              Ready To Grow?
            </div>

            <div className='text-[#789D59] text-sm md:text-lg font-semibold'>
              Join futr Connect and transform the way your tech
              connects with the world
            </div>

          </div>

          <div className="w-full sm:w-1/3 sm:m-auto">
            <button
              className="btn bg-aqua-1 rounded-full p-3 md:p-5 text-white w-full font-semibold"
              onClick={() => navigate("/register")}
            >
              Join Now
            </button>
          </div>

        </div>

      </div>

      {/* Section 5 : Footer */}
      <Footer />
    </div>
  )
}

export default Pricing