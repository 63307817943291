import React, { useRef, useState } from "react";
import useFirebaseAuth from "../../../auth/useFirebaseAuth";
import { postFormMethod, postMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import { ToastContainer } from "react-toastify";

function AddAddress({ onClickClose, setRefresher }) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
  };

  const [address, setAddress] = useState({
    address: "",
    zipCode: "",
    city: "",
    state: "",
  });

  const handleAddressChange = (e) => {
    setAddress({ ...address, [e.currentTarget.name]: e.target.value });
  };

  const handleZipCodeChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9\s\-]*$/.test(value) && value.length <= 10) {
      setAddress({ ...address, zipCode: value });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if(address?.zipCode < 3 || address?.zipCode[0] === " " || address?.zipCode[-1] === " " ||address?.zipCode[0] === "-" || address?.zipCode[-1] === "-"){
      toastError("Zip Code must atleast be 3 characters long and must not start or end with space or '-''.")
    }else{

      var raw = JSON.stringify({
        address: address?.address,
        city: address?.city,
        state: address?.state,
        zipCode: address?.zipCode,
      });
      postMethod("company/addAddress", raw)
        .then((res) => {
          if (res.status == true) {
            console.log(res);
            toastSuccess(res.message);
            // addAddress();
            setAddress({ address: "", zipCode: "", state: "", city: "" });
            onClickClose();
            setRefresher();
          }
        })
        .catch((error) => {
          console.log(error.message);
          toastError(error);
        });
    }

  };

  const validateValue = (e) =>{
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(e.target.value)) {
      handleAddressChange(e)
    }
  }

  return (
    <div className="modal-wrapper">
      <form
        className="flex flex-col bg-white rounded-2xl w-11/12 lg:w-1/2 p-10  gap-5"
        style={modalStyle}
        onSubmit={submitHandler}
      >
        <div className="flex w-full justify-between items-center mb-5">
          <div className="text-2xl  capitalize font-semibold">
            Add New Address
          </div>

          <div
            role="button"
            className="text-sea-green
         border border-sea-green px-5 py-3 rounded-md"
            onClick={onClickClose}
          >
            Close
          </div>
        </div>

        <div className="flex flex-col gap-2 rounded-xl border border-grey-8 p-3">
          <div className="flex items-center rounded-lg border border-grey-7 h-12">
            <input
              className="focus:border-transparent outline-none mx-5 w-full h-full text-xs md:text-normal"
              placeholder="Enter Address"
              value={address?.address}
              onChange={(e) => handleAddressChange(e)}
              name="address"
              required={true}
            />
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
            <div className="flex items-center rounded-lg border border-grey-7 h-12">
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full text-xs md:text-normal"
                placeholder="Enter City"
                value={address?.city}
                onChange={e => validateValue(e)}
                name="city"
                required={true}
              />
            </div>
            <div className="flex items-center rounded-lg border border-grey-7 h-12">
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full text-xs md:text-normal"
                placeholder="Enter State"
                value={address?.state}
                // onChange={(e) => handleAddressChange(e)}
                onChange={e => validateValue(e)}
                name="state"
                required={true}
              />
            </div>
            <div className="flex items-center rounded-lg border border-grey-7 h-12">
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full text-xs md:text-normal"
                placeholder="Enter Zip/postal code"
                value={address?.zipCode}
                onChange={(e) => handleZipCodeChange(e)}
                name="zipCode"
                required={true}
              />
            </div>
          </div>
        </div>

        <div className="flex lg:justify-end w-full">
          <button
            className="bg-sea-green text-white text-xl py-3 w-full lg:w-3/12 rounded-md"
            type="submit"
          >
            Add
          </button>
        </div>
      </form>
      <ToastContainer/>
    </div>
  );
}

export default AddAddress;
