import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../Context/StateContext";
import { getMethod, patchMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import { FormatDate } from "../../../utils/dateFormat";
import Pagination from "../../general/TablePagination";
import { getPaginatedIndex } from "../../../utils/paginatedIndex";

function InvoiceData() {
    // State Variables
    const [invoices, setInvoices] = useState([])
    const [keyword, setKeyword] = useState("");
    const { setLoading } = useContext(StateContext);

         // pagination states
      const [limit, setLimit] = useState(15);
      const [pageArr, setPageArr] = useState([1, 2, 3, 4, 5]);
      const [currentPage, setCurrentPage] = useState(1);
      const [totalPages, setTotalPages] = useState(0);

    // Functions
    const getInvoiceList = async () => {
        setLoading(true);
        try {
            let response = await getMethod(`subscription/transactionAndInvoices?type=invoice&keyword=${keyword}&limit=${limit}&page=${currentPage}`);
            if (response?.status === true) {
                setInvoices(response?.data?.results);
                setTotalPages(response?.data?.totalPages);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error?.message);
        }
    };
    useEffect(() => {
        getInvoiceList();
    }, [currentPage, limit]);

    useEffect(() => {
        if (keyword === "") {
            // Fetch full user list if keyword is cleared
            getInvoiceList();
        } else if (keyword.length >= 3 && keyword.length % 3 === 0) {
            // Call API when keyword length meets the conditions
            getInvoiceList();
        }
    }, [keyword]);
    return (
        <div className="bg-white flex flex-col gap-2 rounded-lg">
            {/* Search bar header */}
            <div className="rounded-t-lg flex gap-5 bg-aqua-2 p-2">
                <div className="text-lg text-sea-green font-bold">Invoice Details</div>

                <form className="rounded-md bg-white w-1/2 px-2 flex gap-2 items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                            stroke="#A1A1AA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <input
                        className={`outline-none w-full ${invoices?.length < 1 && keyword?.length < 1 && "cursor-not-allowed"}`}
                        placeholder="Search with invoice ID..."
                        value={keyword}
                        onChange={(e) => { setKeyword(e.target.value); setCurrentPage(1); }}
                        disabled={invoices?.length < 1 && keyword?.length < 1}
                    />
                </form>
            </div>

            {
                invoices?.length < 1 && keyword?.length < 1 ?
                <div className='w-[500px] m-auto flex flex-col pb-5'>
                <img src="/assets/no-data-available-gif.gif" className='w-full h-full' />
                <div className="font-poppins text-center w-10/12 m-auto">
                No data available . Please subscribe to a plan
                to generate an invoice
                </div>
            </div>
                   : <>
                        {/* Table */}
                        <div className="h-[750px] overflow-auto hide-scrollbar">
                            <table className="w-full">
                                <thead className="text-sea-green text-left border-b">
                                    <tr className="text-center">
                                        <th className="p-2 w-[50px]">SNo.</th>
                                        <th className="p-2 w-[150px]">Invoice ID</th>
                                        <th className="p-2 w-[150px]">
                                            Status
                                        </th>
                                        <th className="p-2 w-[250px]">Date
                                        </th>
                                        <th className="p-2 w-[150px]">Plan</th>
                                        <th className="p-2 w-[150px]">Amount</th>
                                        <th className="p-2 w-[250px]">Discounted Amount</th>
                                        <th className="p-2 w-[300px]">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices?.map((item, index) => (
                                        <tr className="border-b text-center">
                                        <td className="p-2 w-[50px]">{getPaginatedIndex(index, currentPage, limit)}.</td>
                                        <td className="p-2 w-[150px]">{item?.chargebeeInvoiceId}</td>
                                        <td className="p-2 w-[150px] capitalize">
                                            {item?.status}
                                        </td>
                                        <td className="p-2 w-[250px]">{FormatDate(item?.createdAt)}
                                        </td>
                                        <td className="p-2  w-[150px] capitalize">{item?.companySubscription?.type}</td>
                                        <td className="p-2 w-[150px]">${item?.amount}</td>
                                        <td className="p-2 w-[150px]">{item?.discountedAmount? `$ ${item?.discountedAmount}`: "N/A"}</td>
                                        <td className="p-2 flex gap-20 justify-center w-[300px] text-sea-green font-semibold">
                                            {/* <a role="button" href={item?.invoiceUrl} target="_blank" rel="noopener noreferrer">
                                                View
                                            </a>
                                            <div>
                                                |
                                            </div> */}

                                            <a role="button" href={item?.invoiceUrl} download>
                                                Download
                                            </a>
                                        </td>
                                        </tr>
                                 ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            limit={limit}
                            setLimit={setLimit}
                            pageArr={pageArr}
                            setPageArr={setPageArr}
                            totalPages={totalPages}
                        />
                    </>
                    
                  
            }

        </div>



    );
}

export default InvoiceData;
