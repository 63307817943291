import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '../general/NewFooter';
import useFirebaseAuth from "../../auth/useFirebaseAuth";
import { getCompanyCookie, getOnBoardCookie, removeCompanyCookie, removeCompanyUserCookie, removeOnBoardCookie, removeToken } from '../../auth/cookies';
import { toastInfo } from '../../utils/Toasters';
import { StateContext } from '../../Context/StateContext';
import { ToastContainer } from 'react-toastify';

const SubscribeNow = () => {
  const { signOut } = useFirebaseAuth();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  let source = params?.get('source')
  const [billType, setBillType] = useState("annual")
  const [isRender, setIsRender] = useState(false);
  const cookie = getOnBoardCookie();
  const { subscriptionStatus } = useContext(StateContext)

  function handleLogout() {
    removeCompanyCookie();
    removeCompanyUserCookie();
    removeToken();
    removeOnBoardCookie();
    signOut();

    toastInfo("User logged out!");
    navigate("/");
  }
  useEffect(() => {
    if (source && source == "email") {
      if (cookie == null || cookie == undefined || cookie === "") {
        navigate("/home?to=subscribe-now")
      } else {
        setIsRender(true)
      }
    }
    else {
      setIsRender(true)
    }
  }, [])

  return (

    isRender ?

      <div className="flex flex-col bg-white h-screen">
        {/* Header */}
        <div className="bg-white w-full">
          <div className="flex">
            <div className="w-1/4 bg-dark-blue-1 h-3"> </div>
            <div className="w-1/4 bg-aqua-1 h-3"> </div>
            <div className="w-1/4 bg-sea-green h-3"> </div>
            <div className="w-1/4 bg-yellow-1 h-3"> </div>
          </div>
          <div className="flex justify-center mt-3 mb-5">
            <div className="flex justify-center md:justify-between items-center w-10/12">
              <div className="">
                <img src="assets/FutrLogo.png" alt="" className="h-32" />
              </div>

              <div role='button' onClick={() => {
                 // when no cookie available
                if (cookie == null || cookie == undefined || cookie === "") {
                  navigate("/")
                }
                // when cookie available
                else {
                  // but subscription needed
                  if (subscriptionStatus === "subscription_active" || subscriptionStatus === "free") {
                    navigate("/company?activePage=dashboard")
                  } else {
                    handleLogout();
                  }
                }
              }
              }>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                  <path d="M22.4 43.488L32 33.888L41.6 43.488L43.488 41.6L33.888 32L43.488 22.4L41.6 20.512L32 30.112L22.4 20.512L20.512 22.4L30.112 32L20.512 41.6L22.4 43.488ZM32.008 56C28.6907 56 25.5707 55.3707 22.648 54.112C19.7271 52.8516 17.1858 51.1413 15.024 48.9813C12.8622 46.8231 11.1511 44.2844 9.89067 41.3653C8.63022 38.4462 8 35.3271 8 32.008C8 28.6907 8.62933 25.5707 9.888 22.648C11.1484 19.7271 12.8587 17.1858 15.0187 15.024C17.1769 12.8622 19.7156 11.1511 22.6347 9.89067C25.5538 8.63022 28.6729 8 31.992 8C35.3093 8 38.4293 8.62933 41.352 9.888C44.2729 11.1484 46.8142 12.8587 48.976 15.0187C51.1378 17.1769 52.8489 19.7156 54.1093 22.6347C55.3698 25.5538 56 28.6729 56 31.992C56 35.3093 55.3707 38.4293 54.112 41.352C52.8516 44.2729 51.1413 46.8142 48.9813 48.976C46.8231 51.1378 44.2844 52.8489 41.3653 54.1093C38.4462 55.3698 35.3271 56 32.008 56Z" fill="#BA1C1C" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* Section 1 : Power Up Sales Pipeline */}
        <div className='flex flex-col items-center gap-10 mt-10'>
          <div className='flex flex-col items-center gap-5 mb-20'>

            <div className='text-aqua-1 m-auto text-4xl md:text-[72px] font-extrabold w-full md:w-3/5 leading-tight tracking-wide text-center'>

              Power Up Your Sales Pipeline
              with futr Connect

            </div>

            <div className='text-[#1E7D9E] text-center text-lg w-5/6 md:w-2/5'>
              Ready to revolutionize your business development? futr Connect puts your tech solutions at the center of a thriving, self-service ecosystem built to drive engagement, leads, and growth. With our exclusive subscription package, you’ll be able to create a powerful presence that attracts the right customers at the right time, 24/7 - 365!
            </div>
          </div>

          <div className='bg-dark-blue-1 w-5/6 md:w-3/5 rounded-tr-[50px] md:rounded-tr-[100px] px-16 py-16 flex flex-col gap-5 items-center'>

            <div className='text-3xl md:text-5xl text-white'>
              Annual Subscription
            </div>

            <div className='text-md md:text-xl text-yellow-1 font-semibold'>
              1 Year Commitment
            </div>



          </div>




        </div>

        {/* Section 2 : white - What's Included */}

        <div className="bg-[linear-gradient(180deg,_#F5F5F5_0%,_#EEF9FB_94.91%)] pb-32">

          <div className='bg-white box-shadow5 w-5/6 md:w-3/5 flex flex-col items-center gap-10 py-10 px-5 md:px-20 m-auto relative rounded-bl-[50px] lg:rounded-bl-[100px]'>

            <div className='absolute top-0 m-auto'>
              <svg xmlns="http://www.w3.org/2000/svg" width="52" height="30" viewBox="0 0 52 30" fill="none">
                <path d="M26 30L0.0192363 -1.0793e-06L51.9808 3.46332e-06L26 30Z" fill="#1B6283" />
              </svg>
            </div>

            <p className='text-aqua-1 text-lg lg:text-3xl font-medium text-start w-full'>
              <i>What's Included:</i>
            </p>

            <div className='flex flex-col gap-10 w-full'>


              <div className='flex gap-2 md:gap-8'>

                <div className='hidden md:block'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M50.1599 7.92004H13.1999C10.2695 7.92004 7.91992 10.296 7.91992 13.2V50.16C7.91992 53.064 10.2695 55.44 13.1999 55.44H50.1599C53.0903 55.44 55.4399 53.064 55.4399 50.16V13.2C55.4399 10.296 53.0903 7.92004 50.1599 7.92004ZM26.3999 44.88L13.1999 31.68L16.9223 27.9576L26.3999 37.4088L46.4375 17.3712L50.1599 21.12L26.3999 44.88Z" fill="#2196F3" />
                  </svg>
                </div>

                <div className='flex flex-col gap-3 w-full md:w-4/5'>

                  <div className='text-dark-blue-1 font-semibold text-xl md:text-3xl'>
                    <i>Full Control of Your Profile</i>
                  </div>

                  <div className='text:md md:text-xl'>
                    Take charge of your brand. Don’t let a Google search tell your story. Customize your profile to showcase what makes your company unique and update it anytime with the latest on your company and tech solutions.
                  </div>

                </div>

              </div>
              <div className='flex gap-8'>

                <div className='hidden md:block'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M50.1599 7.92004H13.1999C10.2695 7.92004 7.91992 10.296 7.91992 13.2V50.16C7.91992 53.064 10.2695 55.44 13.1999 55.44H50.1599C53.0903 55.44 55.4399 53.064 55.4399 50.16V13.2C55.4399 10.296 53.0903 7.92004 50.1599 7.92004ZM26.3999 44.88L13.1999 31.68L16.9223 27.9576L26.3999 37.4088L46.4375 17.3712L50.1599 21.12L26.3999 44.88Z" fill="#2196F3" />
                  </svg>
                </div>

                <div className='flex flex-col gap-3  w-full md:w-4/5'>

                  <div className='text-dark-blue-1 font-semibold text-xl md:text-3xl'>
                    <i>Engage with Followers</i>
                  </div>

                  <div className='text:md md:text-xl'>
                    Connect with interested users who can follow your company for real-time updates, keeping them engaged with every innovation and achievement.
                  </div>

                </div>

              </div>
              <div className='flex gap-8'>

                <div className='hidden md:block'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M50.1599 7.92004H13.1999C10.2695 7.92004 7.91992 10.296 7.91992 13.2V50.16C7.91992 53.064 10.2695 55.44 13.1999 55.44H50.1599C53.0903 55.44 55.4399 53.064 55.4399 50.16V13.2C55.4399 10.296 53.0903 7.92004 50.1599 7.92004ZM26.3999 44.88L13.1999 31.68L16.9223 27.9576L26.3999 37.4088L46.4375 17.3712L50.1599 21.12L26.3999 44.88Z" fill="#2196F3" />
                  </svg>
                </div>

                <div className='flex flex-col gap-3  w-full md:w-4/5'>

                  <div className='text-dark-blue-1 font-semibold text-xl md:text-3xl'>
                    <i>Direct Meeting Scheduling</i>
                  </div>

                  <div className='text:md md:text-xl'>
                    Cut through the noise. Allow potential clients to schedule meetings directly with your team, streamlining the path to connection.
                  </div>

                </div>

              </div>
              <div className='flex gap-8'>

                <div className='hidden md:block'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M50.1599 7.92004H13.1999C10.2695 7.92004 7.91992 10.296 7.91992 13.2V50.16C7.91992 53.064 10.2695 55.44 13.1999 55.44H50.1599C53.0903 55.44 55.4399 53.064 55.4399 50.16V13.2C55.4399 10.296 53.0903 7.92004 50.1599 7.92004ZM26.3999 44.88L13.1999 31.68L16.9223 27.9576L26.3999 37.4088L46.4375 17.3712L50.1599 21.12L26.3999 44.88Z" fill="#2196F3" />
                  </svg>
                </div>

                <div className='flex flex-col gap-3  w-full md:w-4/5'>

                  <div className='text-dark-blue-1 font-semibold text-xl md:text-3xl'>
                    <i>Customer Feedback</i>
                  </div>

                  <div className='text:md md:text-xl'>
                    Get instant, actionable feedback from users to refine and enhance your offering.
                  </div>

                </div>

              </div>
              <div className='flex gap-8'>

                <div className='hidden md:block'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M50.1599 7.92004H13.1999C10.2695 7.92004 7.91992 10.296 7.91992 13.2V50.16C7.91992 53.064 10.2695 55.44 13.1999 55.44H50.1599C53.0903 55.44 55.4399 53.064 55.4399 50.16V13.2C55.4399 10.296 53.0903 7.92004 50.1599 7.92004ZM26.3999 44.88L13.1999 31.68L16.9223 27.9576L26.3999 37.4088L46.4375 17.3712L50.1599 21.12L26.3999 44.88Z" fill="#2196F3" />
                  </svg>
                </div>

                <div className='flex flex-col gap-3 w-full md:w-4/5'>

                  <div className='text-dark-blue-1 font-semibold text-xl md:text-3xl'>
                    <i>Email Notifications & Analytics</i>
                  </div>

                  <div className='text:md md:text-xl'>
                    Stay in the loop. Receive regular notifications on profile engagement and access detailed analytics to track your success and optimize your strategy.
                  </div>

                </div>

              </div>

            </div>



            {/* Pricing */}

            <div className='flex flex-col md:flex-row justify-center border-t border-t-sea-green w-full'>

              <div className='flex gap-3 items-center w-full relative overflow-clip'>

                <div className='w-[210px] h-[275px] md:h-[255px] rotate-[40deg] bg-sea-green absolute -left-40 bottom-3 flex justify-end items-start flex-shrink-0 p-1'>

                  <div className='text-white -rotate-[40deg] flex flex-col items-center justify-center pt-10 pr-5'>

                    <div className='text-lg font-semibold'>
                      17%
                    </div>
                    <div className='text-sm font-semibold'>

                      Savings
                    </div>

                  </div>

                </div>

                <div className='flex flex-col gap-3 my-5 w-full items-end lg:items-center pb-3 lg:pb-0 border-b-4 border-b-sea-green lg:border-b-0 lg:border-r-4 lg:border-r-sea-green '>

                  <div className='text-sea-green text-lg'>
                    <i>Billed yearly</i>
                  </div>

                  <div className='text-3xl font-bold text-dark-blue-1'>
                    $25,000
                  </div>

                  <div className='flex flex-col'>
                    <input id="yearly" name="billType" type='radio' className='m-auto hover:cursor-pointer' onChange={() => setBillType("annual")} checked={billType === "annual"} />
                    <label className={`text-xl ${billType === "annual"?"text-sea-green ":"text-grey-7"} font-semibold m-auto lg:m-0`} for="yearly">Select</label>
                  </div>
                </div>


              </div>
              <div className='flex flex-col gap-3 ms-auto items-end lg:items-center w-full  lg:m-auto'>

                <div className='text-sea-green text-lg'>
                  <i>Billed monthly</i>
                </div>

                <div className='text-3xl font-bold text-dark-blue-1'>
                  $2,500/mo
                </div>

                <div className='flex flex-col'>
                  <input id="yearly" name="billType" type='radio' className='m-auto hover:cursor-pointer' onChange={() => setBillType("monthly")} checked={billType === "monthly"} />
                  <label className={`text-xl ${billType === "monthly"?"text-sea-green ":"text-grey-7"} font-semibold m-auto lg:m-0`} for="yearly">Select</label>
                </div>


              </div>

            </div>


            <div className="w-full sm:w-3/5 sm:m-auto flex justify-center">
              <button
                className="btn bg-aqua-1 rounded-full p-3 md:p-5 text-white w-3/4 font-bold"
                onClick={() => navigate(`/payment?plan=${billType}`)}
              >
                Subscribe Now
              </button>
            </div>

          </div>

        </div>

        {/* Section 5 : Footer */}
        <Footer />
        <ToastContainer/>
      </div> : <></>
    )
}

export default SubscribeNow