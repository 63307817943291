import React, { useState, useContext } from "react";
import ReactDOM from "react-dom";
import MarkVideoModal from "./MarkVideoModal";
import { ContentVideoContext } from "../../../../Context/ContentVideoContext";
import ReactPlayer from "react-player";
import VideoUpload from "../../modals/VideoUpload";
import DeleteConfirmation from "../../modals/DeleteConfirmation";
import { StateContext } from "../../../../Context/StateContext";
import { getCompanyUserCookie } from "../../../../auth/cookies";

export default function ContentCard({
  video,
  title,
  category,
  markedVideo,
  onEdit,
  onDelete,
  id,
  setRefresher,
  ...attributes
}) {
  const {profileStatus} = useContext(StateContext);
  const userRole = getCompanyUserCookie('userRole');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openMarkAsModal, setOpenMarkAsModal] = useState(false);

  const { setVideoRemark } = useContext(ContentVideoContext);

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClick = () => {
    // console.log("ID in product compo: ", id)
    // handleDelete(id)
    setShowDeleteModal(true);
  };
  const deleteHandler = (id) => {
    onDelete(id);
    setOpenDropdown(false);
  };

  const handleThumbnailClick = () => {
    setIsVideoPlaying(true);
    setOpenVideoModal(true);
  };

  return (
    <div className="relative flex flex-col bg-white rounded-lg product-card-shadow w-full my-3">
      {/* image */}

      <div
        className="relative h-[180px] 
                rounded-t-lg bg-gradient-to-b from-black-1 via-grey-3 
                to-black-1 cursor-pointer"
        onClick={handleThumbnailClick}
      >
        <img
          src={video}
          alt="title"
          className="absolute rounded-t-lg h-full w-full object-cover mix-blend-overlay opacity-75"
        />
      </div>

      <div className="flex px-2 py-5 justify-between">
        {/* CONTENT */}

        <div className="flex flex-col gap-2 w-10/12">
          {/* Description */}

          <div className="text-black-1 font-semibold leading-5 text-sm overflow-hidden max-w-prose line-clamp-1 w-full">
            {title}
          </div>

          {/* label */}

          <div className="flex flex-shrink-0 max-w-max items-center gap-1 bg-light-sea-green rounded-full px-1.5 py-1">
            <div className="flex justify-center items-center rounded-full bg-white w-5 h-5">
              <img
                src="assets/podcast-icon.svg"
                alt="category"
                className="w-3"
              />
            </div>
            <div className="text-sea-green text-xs leading-[18px] font-normal pe-2">
              {category?.name}
            </div>
          </div>
        </div>

        <div className="relative">
          {
                profileStatus !== "1" && userRole !== "viewer" &&
          <div
            className="flex align-center justify-center rounded-full bg-light-sea-green p-1 h-6 w-6 cursor-pointer"
            onClick={() => {
              setOpenDropdown(!openDropdown);
            }}
          >
            <img src="assets/menu-kebab.svg" />
          </div>
          }

          {openDropdown && 
         
          (
            <div
              className={`absolute ${openMarkAsModal ? "right-32" :"right-6" } bottom-0 bg-white box-shadow2 
                      rounded-md flex flex-col gap-2 text-sm py-3 px-2 w-[150px]`}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  onEdit(true, id);
                  setVideoRemark({
                    type: "btn3",
                    status: true,
                    value: markedVideo,
                  });
                }}
              >
                Edit
              </div>

              <div
                className="flex justify-between cursor-pointer items-center gap-2"
                onClick={() => setOpenMarkAsModal(!openMarkAsModal)}
              >
                <p className="w-fit">Mark video as</p>
                <div>
                  <img
                    width={15}
                    src={`${
                      openMarkAsModal
                        ? "/assets/arrow-right-curved.svg"
                        : "/assets/arrow-down-curved.svg"
                    }`}
                  />
                </div>
              </div>
              <div
                className="text-red cursor-pointer"
                onClick={handleDeleteClick}
              >
                Remove
              </div>

              
            </div>
            
            // <div className="h-[200px] w-[250px] bg-red absolute top-0 left-0 z-50">

            //   Edit

            // </div>
          )
          }
          {openMarkAsModal && (
                <MarkVideoModal
                  contentId={id}
                  clickHandler={() => {
                    setOpenMarkAsModal(false);
                    setOpenDropdown(false);
                  }}
                  setRefresher={setRefresher}
                />
              )}
        </div>

        {/* MENU KEBAB */}
      </div>

      {isVideoPlaying && (
        <VideoUpload
          videoUrl={attributes?.videoUrl}
          closeHandler={() => setIsVideoPlaying(false)}
        />
      )}

      {showDeleteModal && (
        <DeleteConfirmation
          setShowModal={setShowDeleteModal}
          deleteHandler={() => deleteHandler(id)}
        />
      )}


    </div>

  //   <div className="flex flex-col bg-white rounded-lg product-card-shadow w-[320px]">
  //   <div
  //   className="h-[124px] border border-red rounded-t-lg overflow-hidden"
  //   // onClick={handleThumbnailClick}
  // >
  //   <img
  //     src="/assets/product-image-1.png"
  //     alt="title"
  //     className="object-cover max-h-full w-full"
  //   />
  // </div>
  // <div className="flex p-2 justify-between">
  //   {/* CONTENT */}

  //   <div className="flex flex-col gap-2 w-10/12">
  //     {/* Description */}

  //     <div className="text-black-1 font-semibold leading-5 text-sm overflow-hidden max-w-prose line-clamp-1 w-full">
  //       title
  //     </div>

  //     {/* label */}

  //     <div className="flex flex-shrink-0 max-w-max items-center gap-1 bg-light-sea-green rounded-full px-1.5 py-1">
  //       <div className="flex justify-center items-center rounded-full bg-white w-5 h-5">
  //         <img
  //           src="assets/podcast-icon.svg"
  //           alt="category"
  //           className="w-3"
  //         />
  //       </div>
  //       <div className="text-sea-green text-xs leading-[18px] font-normal pe-2">
  //         category?.name
  //       </div>
  //     </div>
  //   </div>

  //   <div className="relative">
  //     <div
  //       className="flex align-center justify-center rounded-full bg-light-sea-green p-1 h-6 w-6 cursor-pointer"
  //       onClick={() => {
  //         // setOpenDropdown(!openDropdown);
  //         setOpenDropdown(!openDropdown)
  //       }}
  //     >
  //       <img src="assets/menu-kebab.svg" />
  //     </div>

  //     {openDropdown && 
     
  //     (
  //       // <div
  //       //   className="absolute top-[10rem] -left-[1rem] bg-white box-shadow2 
  //       //           rounded-md flex flex-col gap-2 text-sm p-5 w-full z-50"
  //       // >
  //       //   <div
  //       //     className="cursor-pointer"
  //       //     onClick={() => {
  //       //       onEdit(true, id);
  //       //       setVideoRemark({
  //       //         type: "btn3",
  //       //         status: true,
  //       //         value: markedVideo,
  //       //       });
  //       //     }}
  //       //   >
  //       //     Edit
  //       //   </div>

  //       //   <div
  //       //     className="flex justify-between cursor-pointer"
  //       //     onClick={() => setOpenMarkAsModal(!openMarkAsModal)}
  //       //   >
  //       //     <div>Mark video as</div>
  //       //     <div>
  //       //       <img
  //       //         width={15}
  //       //         src={`${
  //       //           openMarkAsModal
  //       //             ? "/assets/arrow-right-curved.svg"
  //       //             : "/assets/arrow-down-curved.svg"
  //       //         }`}
  //       //       />
  //       //     </div>
  //       //   </div>
  //       //   <div
  //       //     className="text-red cursor-pointer"
  //       //     onClick={handleDeleteClick}
  //       //   >
  //       //     Remove
  //       //   </div>

  //       //   {openMarkAsModal && (
  //       //     <MarkVideoModal
  //       //       contentId={id}
  //       //       clickHandler={() => {
  //       //         setOpenMarkAsModal(false);
  //       //         setOpenDropdown(false);
  //       //       }}
  //       //       setRefresher={setRefresher}
  //       //     />
  //       //   )}
  //       // </div>
  //       <div className="h-[200px] w-[250px] bg-red absolute top-8 left-0 z-50">

  //         Edit

  //       </div>
  //     )
  //     }
  //   </div>

  //   {/* MENU KEBAB */}
  // </div>
  //   </div>



    
  );
}
