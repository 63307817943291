import React, { useContext, useEffect, useState } from 'react'

import { getOnBoardCookie } from '../auth/cookies'
import { useNavigate, useSearchParams } from 'react-router-dom';
import firebase from "../auth/firebaseConfig";
import useFirebaseAuth from '../auth/useFirebaseAuth';
import {removeOnBoardCookie} from "../auth/cookies"
import { StateContext } from '../Context/StateContext';
import { getMethod } from './apiMethod';
import { toastInfo } from './Toasters';

function ProtectedRoutes({component}) {

  const [isRender, setIsRender] = useState(false);

    // const token = getToken('auth');
    const token = getOnBoardCookie()
    const navigate = useNavigate();
    const {signOut} = useFirebaseAuth();
    const {subscriptionStatus, setSubscriptionStatus, setLoading} = useContext(StateContext)
    const [searchParams] = useSearchParams();


    useEffect(() => {
      function parseJwt() {
        const base64Url = token?.split(".")[1];
        const base64 = base64Url?.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
      }

        if(!token && searchParams?.get('source')==='email') {
          signOut();
            navigate("/home?to=subscribe-now");
        }
       else if(!token && (searchParams?.get('source')== null)) {
          signOut();
            navigate("/");
        }
        else if(token &&  parseJwt().email_verified === false){
          signOut();
          removeOnBoardCookie();
          navigate("/");
        }
        
      }, [token, navigate])

        useEffect(() => {
          if(window.location.pathname === "/linkedInAuth"){
          // do nothing
          }else{
            setLoading(true);
          
            getMethod(`company/getMyCompanyDetails`)
              .then((res) => {
                setLoading(false); 
                if (res?.status) {
                  if(isRender === false){
  
                    setIsRender(true);
                  }
                  if((res?.company?.company?.subscriptionStatus ==="subscription_active" || res?.company?.company?.subscriptionStatus ==="free" || res?.company?.company?.subscriptionStatus === "cancellation_scheduled" || window.location.pathname === "/linkedInAuth") && token){
                    // do nothing
                  }else{
                    navigate("/subscribe-now")
  
                  }
                  
                }else{
                  if(res?.code == 426){
                    // setIsRender(true);
                    navigate("/subscribe-now");
                    toastInfo(res?.message)
                  }
                }
              })
              .catch((error) => {
                console.log("error.message: ", error.message);
              });
          }
         
        }, []);
  if(!isRender){
    return <></>;
  }
  return (
    <div>
      {isRender && component}
    </div>
  )
  // return (
  //   <div>
  //    {component}
  //   </div>
  // )
}

export default ProtectedRoutes