import React from 'react';
import chargebee from 'chargebee-js';
import { getMethod } from '../../utils/apiMethod';

const HostedPage = () => {

  const handleCheckout = async () => {
    try {
      // Trigger hosted page session from backend
      const response = await getMethod("subscription/checkoutHostedPage/monthly")

      if (response.hosted_page) {
        // Redirect to Chargebee hosted page
        window.location.href = response?.hosted_page?.url;
      }
    } catch (error) {
      console.error('Error initiating hosted page:', error);
    }
  };

  return (
    <div>
      <button onClick={handleCheckout} className="btn btn-primary">
        Go to Checkout
      </button>
    </div>
  );
};

export default HostedPage;
