//import dependencies
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//import components
import CompanyProfile from './components/CompanyProfile';
import HomePage from './components/HomePage'


//import css files
import './App.css';
import { useContext, useState } from 'react';
import RegistrationForm from './components/subPages/RegistrationForm';
import TestRoute from './components/TestRoute';
import Loader from './components/general/Loader';
import { StateContext } from './Context/StateContext';
import LandngPage from './components/LandngPage';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoutes from './utils/protectedRoutes';
import LinkedInAuth from './components/subPages/LinkedInAuth';
import PageNotFound from './components/general/PageNotFound';
import NewsLoader from './components/general/NewsLoader';
import PrivacyLoader from './components/general/PrivacyLoader';
import ResetPassword from './components/general/ResetPassword';
import Pricing from './components/subscriptions/Pricing';
import Payment from './components/subscriptions/Payment';
import SubscribeNow from './components/subscriptions/SubscribeNow';
import HostedPage from './components/subscriptions/HostedPage';
import { Failure } from './components/general/payment/Failure';
import { Success } from './components/general/payment/Success';


function App() {

  const { loading, newsLoading, privacyLoading, cancelPlanLoading, paymentSuccessLoading } = useContext(StateContext)

  return (

    <div className='flex flex-col'>
     
      {loading && <Loader/>}
      {newsLoading && <NewsLoader message={"We are retrieving the details of your news article."}/>}
      {privacyLoading && <PrivacyLoader/>}
      {cancelPlanLoading && <NewsLoader message={"Please wait till Chargebee responds to your cancellation request."}/>}
      {paymentSuccessLoading && <NewsLoader message={"Heading to dashboard..."}/>}
      {/* <ToastContainer/> */}
      <BrowserRouter>
      
      <Routes>
        

        <Route path='/' element={<HomePage/>} />
        <Route path='/home' element={<HomePage/>} />
        <Route path='/register' element={<RegistrationForm/>} />
        <Route path='/resetPassword' element={<ResetPassword/>} />
        <Route path='/testRoute' element={<TestRoute/>} />
        <Route path='/landingPage' element={<LandngPage/>} />
        <Route path='/linkedInAuth' element={<LinkedInAuth/>} />
        <Route path="/company" element={<ProtectedRoutes component={<CompanyProfile />}/>} />
        <Route path="/*" element={<PageNotFound/>} />
        <Route path="/page-not-found" element={<PageNotFound/>} />
        <Route path="/pricing" element={<Pricing/>} />
        <Route path="/payment" element={<ProtectedRoutes component={<Payment/>}/>}/>
        <Route path="/payment-failure" element={<Failure/>} />
        <Route path="/payment-success" element={<Success/>} />
        <Route path="/subscribe-now" element={<ProtectedRoutes component={<SubscribeNow/>}/>}/>
        <Route path="/chargebee/hosted" element={<ProtectedRoutes component={<HostedPage/>}/>}/>


      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
