import { useContext, useEffect, useState } from "react";
import { deleteMethod, getMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import { StateContext } from "../../../Context/StateContext";
import Reference from "../../general/cards/Reference";
import Pagination from "../../general/Pagination";
import Searchbar from "../../general/Searchbar";
import DataNotFound from "../../general/DataNotFound";
import { getCompanyUserCookie } from "../../../auth/cookies";

function ReferencesAdd({ onEditClick, fetchIsReferenceEdit }) {
  const { setLoading, profileStatus } = useContext(StateContext);
  const userRole = getCompanyUserCookie('userRole')

  // pagination variables & function
  const [limit, setLimit] = useState(3);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  function changeLimitHandler(limit) {
    setLimit(limit);
    setPage(1);
  }

  const [searchParams, setSearchParams] = useState("");

  function fetchKeyword(keyword) {
    setSearchParams(keyword);
    setPage(1)
  }

  const [references, setReferences] = useState([]);

  const deleteReferenceHandler = (referenceId) => {
    // setDelete(false);
    let pageNumber = page;
    deleteMethod(`references/deleteReference/${referenceId}`)
      .then((res) => {
        console.log(res);
        if (res.status === true) {
          //   setOpen(false);
          // setReferences(
          //   references.filter((reference) => reference.id !== referenceId)
          // );
          if(references?.length < 2){
            pageNumber = page - 1
          }
          getReferencesApi(pageNumber,searchParams)
          toastSuccess("Reference deleted!");
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        toastError(error.message);
        console.log("Error: ", error.message);
      });
  };

  function editReferenceHandler(bool, id) {
    const obj = {
      isEdit: bool,
      referenceId: id,
    };
    fetchIsReferenceEdit(obj);
  }

  function getReferencesApi(pageNumber, search){
    setLoading(true);

    console.log("SEARCH PARAMS : ", search);

    getMethod(
      `references/getMyReference?${
        search.length > 0 ? `keyword${search}&` : ""
      }limit=${limit}&page=${pageNumber}`
    )
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setReferences(res.data?.results);

          // pagination variables
          setLimit(res.data?.limit);
          setPage(res.data?.page);
          setTotalPages(res.data?.totalPages);

          console.log("RES: ", res);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }

  useEffect(() => {
    setLoading(true);

    console.log("SEARCH PARAMS : ", searchParams);

    getMethod(
      `references/getMyReference?${
        searchParams.length > 0 ? `keyword${searchParams}&` : ""
      }limit=${limit}&page=${page}`
    )
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setReferences(res.data?.results);

          // pagination variables
          setLimit(res.data?.limit);
          setPage(res.data?.page);
          setTotalPages(res.data?.totalPages);

          console.log("RES: ", res);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, [searchParams, limit, page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-5 lg:p-8 category-content-wrapper-div gap-5">
      {/* header */}
      <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
        <div className="flex flex-col">
          <div className="font-medium text-[28px]">References</div>
          <div className="text-grey-2 text-[16px]">Check Your References.</div>
        </div>
        {
              profileStatus !== "1" && userRole !== "viewer" &&
        <div className="flex lg:justify-end mt-5 lg:mt-0">
          <button className="btn-sea-green-thick" onClick={onEditClick}>
            Add New
          </button>
        </div>
        }
      </div>

      {/* Body */}

      {/* SEARCH BAR */}

      {/* <div className="flex items-center rounded-lg border border-grey-7 h-12">
                <div className=" border-y-0 border-l-0 h-full flex justify-center items-center pl-4">
                    
                       <img src="assets/search.svg" alt="search" className=""/>
                    

                </div>
                <input
                className="focus:border-transparent outline-none mx-5 w-full h-full"
                placeholder="Search"
                value={}
                onChange={}
              />
            </div> */}

      {/* SEARCH BAR */}

      <Searchbar fetchKeyword={fetchKeyword} />

      {references?.length > 0 ? (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-3">
            {references.map((reference, index) => {
              return (
                <Reference
                  name={reference.userName}
                  designation={reference.role}
                  image={reference.referralUserPicUrl}
                  linkedin={reference.linkedinUrl}
                  id={reference.id}
                  companyName={reference.companyName}
                  companyPic={reference.companyImageKeyUrl}
                  handleDelete={deleteReferenceHandler}
                  onEdit={editReferenceHandler}
                />
              );
            })}
          </div>
          {/* PAGINATION */}
          <Pagination
            lim={limit}
            currPage={page}
            pages={totalPages}
            setLim={changeLimitHandler}
            setCurrPage={setPage}
            label={"References"}
            counts = {[3,6,9]}
          />
        </>
      ) : (
        <DataNotFound/>
      )}
    </div>
  );
}

export default ReferencesAdd;
