import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { setCompanyCookie, setOnBoardCookie } from '../../auth/cookies';
import { StateContext } from '../../Context/StateContext';
import { LoginQuery } from '../../utils/auth';
import { toastError, toastSuccess } from '../../utils/Toasters';
import ChooseCompanyType from '../general/modals/ChooseCompanyType';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
function LinkedInAuth() {

  const navigate = useNavigate()

  const [openModal, setOpenModal] = useState(false)
  const [token, setToken] = useState("")

  const query = useQuery();

  const { setLoading } = useContext(StateContext);

  const loginHandler = async (token) => {
    setLoading(true)
    // setToken(token)

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    try {
      fetch(process.env.REACT_APP_BASE_URL + "auth/login", requestOptions).then((res) => { return res.json() })
        .then((response) => {

          const resp = response.company
          console.log("RESPONSE IN LOG : ", resp)
          if (response.status) {
            console.log(response)
            setToken(token)
            setOnBoardCookie(token)
            setCompanyCookie(response)
            toastSuccess('Logged In successfully!')
            setLoading(false)
            navigate('/company?activePage=dashboard')

          } else {
            toastError(response.message);
            setLoading(false)
            // signOut()
          }



        }).catch((error) => {
          console.log("ERROR: ", error)

          setLoading(false)
          toastError(error.message)

        })

    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setToken(query.get("idToken"))

    if (query.get("status") === true || query.get("status") === "true") {
      const linkedInDetails = {
        idToken: query.get("idToken"),
        nextStep: query.get("nextStep"),
      }

      // setOnBoardCookie(linkedInDetails.idToken)
      if (query.get("state") === "company" && query.get("nextStep") === "signup") {
        setOnBoardCookie(linkedInDetails.idToken)
        setOpenModal(true);
      } else {
        loginHandler(linkedInDetails.idToken)
      }
      localStorage.setItem('linkedInCreds', JSON.stringify(linkedInDetails));
      // window.close();
    } else {
      navigate("/")
    }
  }, [])

  return (
    <div>

      Loading ...

      {
        openModal && <ChooseCompanyType token={token} />
      }

      <ToastContainer />

    </div>
  )
}

export default LinkedInAuth