import { ContentVideoContext } from "../../../Context/ContentVideoContext";
import InputField from "../../general/NewInputField";
import React, { useContext, useRef, useState, useEffect } from 'react';
import SelectProductTypeModal from "../../general/SelectProductType";
import DropDownInput from "../../general/DropDownInput";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import { StateContext } from "../../../Context/StateContext";
import { getMethod, postFormMethod, postMethod } from "../../../utils/apiMethod";
import SelectVideoType from "../../general/SelectVideoType";

function ContentEdit({ onBackClick, contentId }) {

  const {setLoading} = useContext(StateContext);
  const { videoSrc, setVideoRemark } = useContext(ContentVideoContext);

  const [videoUrl, setVideoUrl] = useState("");
  const [poster, setPoster] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoType, setVideoType] = useState({});
  const [videoMark, setVideoMark] = useState("");
  const [video, setVideo] = useState(null);
  const [contentData, setContentData] = useState({});

  const fetchSelectedOption = (videoType) => {
    console.log("videoType: ", videoType)
    setVideoType(videoType);
  };
  const fetchVideoMark = (videoMark) => {
    console.log("videoMark: ", videoMark)
    setVideoMark(videoMark);
  };

  const videoRef = useRef(null);
  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  function validateUrl(url) {
    try {
      let validate = new URL(url);

      return true;
    } catch (error) {
      throw new Error(`Video URL not valid!`);
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    setLoading(true);

    try{

      var thumbUrl = poster;
      var url = validateUrl(videoUrl);
  
      if(videoUrl !== contentData?.videoLink && url){
  
            // Generate poster
      
          if (videoUrl.includes('youtube.com')) {
            const videoId = videoUrl.split('v=')[1];
            thumbUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
            setPoster(thumbUrl)
          } else if (videoUrl.includes('vimeo.com')) {
            const videoId = videoUrl.split('.com/')[1];
            // You might need to fetch Vimeo thumbnail using their API
            // Here's a dummy URL for the sake of example
            thumbUrl = `https://vumbnail.com/${videoId}.jpg`;
            setPoster(thumbUrl)
          }
  
      }
  
      var raw = {
  
        contentId:contentId,
        title:videoTitle,
        videoLink:videoUrl,
        poster:thumbUrl,
        
        contentTypeId : videoType?.id
      };
  
  
      if(videoMark !== contentData?.markedVideo || videoMark !== "misc"){
        raw = {...raw,  markedVideo:videoMark}
      }
  
      raw = JSON.stringify(raw)
  
      postMethod(`content/updateContent`, raw)
        .then((res) => {
          setLoading(false);

          if(res?.status === true){
            toastSuccess(res?.message);
            setVideoRemark({ type: "btn1", status: false, value: "" });
            onBackClick();
          }
          else{
            toastError(res?.message)
          }
        
        })
        .catch((error) => {
          setLoading(false);
          toastError(error?.message);
          console.log("error.message: ", error?.message);
        });

    }catch(error){
      setLoading(false)
      toastError(error.message)
    }


   
  };

  useEffect(() => {

    window.scrollTo(0,0)
     
    setLoading(true);
    getMethod(`content/viewContent/${contentId}`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
         console.log(res.data)
         setContentData({...res.data})
         setVideo(res.data.videoUrl);
         setVideoMark(res.data.markedVideo);
         setVideoTitle(res.data.title);
         setVideoUrl(res.data.videoLink);
         setVideoType({...res.data.contentType})
         setPoster(res?.data?.poster)
         
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  useEffect(() => {

    setVideo(videoSrc)
    
  }, [videoSrc])
  
const BackButtonHandler = () => {
  setVideoRemark({ type: "btn1", status: false, value: "" });
  onBackClick();
}

  return (
    <form className="p-5 lg:p-8 category-content-wrapper-div" onSubmit={submitHandler}>
      {/* Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={BackButtonHandler} className="cursor-pointer">
              <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px]">
                Edit Video
              </div>
              <div className="text-grey-2">Add new product details</div>
            </div>
          </div>
        </div>
      </div>

      {/* Form */}
      <div className="flex flex-col gap-5">
        {/* Video info */}
        <div className="form-outline-wrapper-div p-8 gap-8">

          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">

            <InputField label="Video URL"
             placeholder="Add Video URL" 
             width={"full"}
             value={videoUrl} 
             optional={false}
             onChange={ e => setVideoUrl(e.target.value)} />

            <InputField label="Video Title"
             placeholder="Title Your Video" 
             width={"full"}
             value={videoTitle} 
             optional={false}
             onChange={ e => setVideoTitle(e.target.value)} />
           
            <SelectVideoType title={"video"} fetchSelectedOption={fetchSelectedOption} prodTypeDetails={videoType} optional={false}/>
         
            <DropDownInput
             fetchSelectedOption={fetchVideoMark} 
             value={videoMark} 
             placeholder = {"Select Video Type"}
             width={"full"}
             />

          </div>
        </div>
      </div>

      {/* Save changes button */}
      <div className="flex flex-row-reverse">
        <div className="flex lg:justify-end w-full lg:w-2/12">
          <button className="btn-sea-green text-xl w-full" type="submit">Save</button>
        </div>
      </div>
    </form>
  );
}

export default ContentEdit;
