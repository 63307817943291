import React, { useState, useRef } from "react";
import { GrDocumentText } from "react-icons/gr";

function ResourceUpload({ resourcesList, setResourcesList, label, required }) {
  const resourceFileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newResources = Array.from(files).map((file) => ({
      type: file.type.startsWith("image/")
        ? "image"
        : file.type.startsWith("video/")
        ? "video"
        : "file",
      url: URL.createObjectURL(file),
      main: file,
      name: file.name,
    }));

    setResourcesList((prevResources) => [...prevResources, ...newResources]);
  };

  const handleAddMoreResourceClick = () => {
    if (resourceFileInputRef.current) {
      resourceFileInputRef.current.click();
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const newResources = Array.from(droppedFiles).map((file) => ({
      type: file.type.startsWith("image/")
        ? "image"
        : file.type.startsWith("video/")
        ? "video"
        : "file",
      url: URL.createObjectURL(file),
      main: file,
      name: file.name,
    }));

    setResourcesList((prevResources) => [...prevResources, ...newResources]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        {label} {required && <span className="text-orange-1">*</span>}
      </div>

      <div
        className="grid grid-cols-2 lg:grid-cols-5 gap-5"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {resourcesList.length === 0 && (
          <div
            className="flex items-center justify-center rounded-2xl bg-light-sea-green-1 h-28 w-28 cursor-pointer"
            onClick={handleAddMoreResourceClick}
          >
            <div className="m-auto">
              <img src="assets/documentupload.svg" alt="upload" />
            </div>
          </div>
        )}

        {resourcesList?.map((resource, index) => {

          console.log("RESOURCE SINGLE : ", resource)

          return(
      
          <div key={index} className="">
           

            <div className="relative h-28 w-28">

            <div
              className="delete-btn absolute top-2 right-2 cursor-pointer"
              onClick={() => {
                const updatedResources = [...resourcesList];
                updatedResources.splice(index, 1);
                setResourcesList(updatedResources);
              }}
            >
              <img src="assets/trash.svg" alt="delete" />
            </div>

            {resource.fileType === "image" ? (
              <img
                src={resource.url}
                alt={resource.name}
                className="rounded-2xl h-28 w-28 object-cover"
              />
            ) : resource.fileType === "video" ? (
              <video
                src={resource.url}
                controls
                className="rounded-2xl h-28 w-28"
              />
            ) : (
              <div className="rounded-2xl bg-grey-5 h-28 w-28 flex flex-col justify-center items-center">
                <GrDocumentText size={50} className="text-grey-10" />
                <div className="text-xs text-center mt-2 break-words px-2 overflow-hidden text-ellipsis whitespace-nowrap w-full">
                  {resource.name}
                </div>
              </div>
            )}

            </div>
          
          </div>

          )
})}

        <input
          type="file"
          ref={resourceFileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.key,.csv,.numbers,.pages"
          multiple
        />
      </div>

      {resourcesList.length < 5 && (
        <div
          className="text-sea-green font-medium cursor-pointer"
          onClick={handleAddMoreResourceClick}
        >
          {resourcesList.length > 0 ? "Add more" : "Add"}
        </div>
      )}
    </div>
  );
}

export default ResourceUpload;