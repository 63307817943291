import { createContext, useEffect, useState } from "react";
import { getMethod } from "../utils/apiMethod";
import { getOnBoardCookie } from "../auth/cookies";

export const StateContext = createContext();


const StateProvider = ({ children }) => {
  const token = getOnBoardCookie()
  const [loading, setLoading] = useState(false)
  const [newsLoading, setNewsLoading] = useState(false)
  const [privacyLoading, setPrivacyLoading] = useState(false)
  const [cancelPlanLoading, setCancelPlanLoading] = useState(false)
  const [paymentSuccessLoading, setPaymentSuccessLoading] = useState(false)
  const [loadingTopContent, setLoadingTopContent] = useState(false)

  const [companyOwnerName, setCompanyOwnerName] = useState("")
  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyBio, setCompanyBio] = useState("");

  const [refresh, setRefresh] = useState(true)
  const [refreshHeader, setRefreshHeader] = useState(true)

  const [topEngagedSection, setTopEngagedSection] = useState("");
  const [profileStatus, setProfileStatus] = useState("")

  const [showTrialModal, setShowTrialModal] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");


  useEffect(() => {
              if(window.location.pathname === "/linkedInAuth"){
              // do nothing
              }else{
                getMethod(`company/getMyCompanyDetails`)
                  .then((res) => {
                    if (res?.status) {
                      setProfileStatus(res?.company?.company?.profileStatus);
                      setSubscriptionStatus(res?.company?.company?.subscriptionStatus);
                      if((res?.company?.company?.subscriptionStatus ==="subscription_active" || res?.company?.company?.subscriptionStatus ==="free" || res?.company?.company?.subscriptionStatus === "cancellation_scheduled" || window.location.pathname === "/linkedInAuth") && token){
                        // do nothing
                      }else{
                        if(window.location.pathname !== "/subscribe-now"){
                          window.location.href = "/subscribe-now"
                        }
                      }
                    }
                  })
                  .catch((error) => {
                    console.log("error.message: ", error.message);
                  });
              }
  }, []);

  return <StateContext.Provider value={{
    loading, setLoading, newsLoading, setNewsLoading, privacyLoading, setPrivacyLoading, cancelPlanLoading, setCancelPlanLoading, paymentSuccessLoading, setPaymentSuccessLoading, loadingTopContent, setLoadingTopContent,
    companyLogo, setCompanyLogo, companyName, setCompanyName, setCompanyOwnerName, companyOwnerName,
    companyBio, setCompanyBio, refresh, setRefresh, refreshHeader, setRefreshHeader, topEngagedSection, setTopEngagedSection, profileStatus, setProfileStatus, showTrialModal, setShowTrialModal, subscriptionStatus, setSubscriptionStatus
  }}>{children}</StateContext.Provider>
}

export default StateProvider