import { React, useEffect, useState, useContext} from 'react'
import VideoCard from '../../general/cards/content/VideoCard'
import { getMethod } from '../../../utils/apiMethod';
import { toastError } from '../../../utils/Toasters';
import AddVideoCard from '../../general/cards/content/AddVideoCard';
import { StateContext } from '../../../Context/StateContext';
import LocalLoader from '../../general/LocalLoader';
import { ContentVideoContext } from '../../../Context/ContentVideoContext';

function TopContent({edit ,openAddVideo, onDelete }) {


  const {loadingTopContent} = useContext(StateContext);
  const {callTopContentApi} = useContext(ContentVideoContext);

    const [featured, setFeatured] = useState({});
    const [firstCall, setFirstCall] = useState({});
    const [demo, setDemo] = useState({});

    const getMyTopContentApi = () => {
      getMethod(`content/getMyTopContent`)
      .then((res) => {
        
        if (res?.status) {
         

                console.log("Response top content : ", res.data)
        
                setDemo(...res?.data?.filter((video)=> video.markedVideo == "demoVideo"));
                setFeatured(...res?.data?.filter((video)=> video.markedVideo == "featuredVideo"));
                setFirstCall(...res?.data?.filter((video)=> video.markedVideo == "videoCall"));
      
        }
      })
      .catch((error) => {

        toastError(error.message);
        console.log("error.message: ", error.message);
      });
    }

    useEffect(() => {

      getMyTopContentApi()
      
    
    }, [callTopContentApi])
    
  return (
    <div className=''>

        {
            loadingTopContent ? (

                <LocalLoader/>

            ) :
            (

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">

                {
                    featured === undefined ? (
                       <AddVideoCard tag={"featured"} openAddVideo={openAddVideo}/>
        
                    ) : (
        
                        <VideoCard title={featured.title} edit = {edit} onDelete={onDelete} image={featured?.poster} 
                        tag="featured" id={featured.id} remark = {featured.markedVideo} videoUrl= {featured?.videoLink}/>
        
                    )
                }
                {
                    firstCall === undefined ? (
                       <AddVideoCard tag={"firstCall"} openAddVideo={openAddVideo}/>
        
                    ) : (
        
                        <VideoCard title={firstCall.title} edit = {edit} onDelete={onDelete}  image={firstCall?.poster} 
                        tag="firstCall" id={firstCall.id} remark = {firstCall.markedVideo} videoUrl= {firstCall?.videoLink}/>
        
                    )
                }
                {
                    demo === undefined ? (
                       <AddVideoCard tag={"demo"} openAddVideo={openAddVideo}/>
        
                    ) : (
        
                        <VideoCard tag="demo" title={demo.title} edit = {edit} onDelete={onDelete}
                          image={demo?.poster}  id={demo.id} remark = {demo.markedVideo} videoUrl= {demo?.videoLink}/>
        
                    )
                }
           
        
        
        </div>

            )
        }
   
  
  

    </div>

  

  )
}

export default TopContent
