import { useContext, useEffect, useRef, useState } from "react";
import InputField from "../../general/NewInputField";
import { postFormMethod } from "../../../utils/apiMethod";
import { StateContext } from "../../../Context/StateContext";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import ImageUpload from "../../general/ImageUpload";
function ReferencesAdd({ onBackClick }) {
  const { setLoading } = useContext(StateContext);

  const [userName, setUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [role, setRole] = useState("");
  const [description, setDescription] = useState("");

  const [userPic, setUserPic] = useState(null);
  const userInputRef = useRef(null);

  // image functions
  const handleClick = () => {
    userInputRef.current.click(); // Trigger the file input dialog
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setUserPic(selectedFile);
    // Do something with the selected file
  };

  const [companyPic, setCompanyPic] = useState(null);
  const companyInputRef = useRef(null);
  // image functions
  const handleCompanyPicClick = () => {
    companyInputRef.current.click(); // Trigger the file input dialog
  };

  const handleCompanyFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setCompanyPic(selectedFile);
    // Do something with the selected file
  };

  
  function validateUrl(url){

    try{

      let validate = new URL(url)
      const isLinkedIn = validate.hostname.includes('linkedin.com') && validate.pathname.startsWith('/in/');
    
    if (!isLinkedIn) {
      throw new Error();
    }

      return true

    }catch(error){
      throw new Error(`LinkedIn URL not valid!`)
    }


  }

  const submitHandler = (e) => {

    e.preventDefault();
    try{

      // checking of the values changed in field

    if(!userPic){
      toastError("Image is required")
    } 
    else{
      setLoading(true);

      var formdata = new FormData();
    if (userPic !== null) {
      formdata.append("userPic", userPic);
    }
    // if (companyPic !== null) {
    //   formdata.append("companyPic", companyPic);
    // }
    if (userName?.trim()?.length > 0) {
      formdata.append("userName", userName);
    }

    if (companyName?.trim()?.length > 0) {
      formdata.append("companyName", companyName);
    }
    if (role?.trim()?.length > 0) {
      formdata.append("role", role);
    }
    if (linkedInUrl?.trim()?.length > 0) {
      validateUrl(linkedInUrl)
      formdata.append("linkedinUrl", linkedInUrl);
    }
    if (description?.trim()?.length > 0) {
      formdata.append("description", description);
    }

    postFormMethod(`references/addReference`, formdata)
      .then((res) => {
        setLoading(false);
        if (res?.status === true) {
          setLoading(false);

          toastSuccess("Reference added successfully!");
          onBackClick();
        } else {
          toastError(res?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error?.message);
        console.log("error.message: ", error?.message);
      });

    }

    }catch(error){
      setLoading(false);
      toastError(error?.message)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form className="p-5 lg:p-8 category-content-wrapper-div" onSubmit={submitHandler}>
      {/*  Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={onBackClick} className="cursor-pointer">
              <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px] capitalize">
                Add reference
              </div>
              <div className="text-grey-2 capitalize">
                Add people you want to refer
              </div>
            </div>
          </div>

          <div className="flex lg:justify-end mt-5 lg:mt-0">
            <button className="btn-sea-green-thick" type="submit">
              Add
            </button>
          </div>
        </div>
      </div>

      {/* Form */}

      <div className="flex flex-col gap-5">
        {/* COMPANY DETAILS */}
        <div className="form-outline-wrapper-div p-8 gap-8">
          {/* Logo input */}
          {/* <div className="flex flex-col gap-4">
            <div>
              Reference Picture or Company Logo<span className="text-orange-1">*</span>
            </div>
            <div className="flex items-center rounded-2xl bg-light-sea-green-1 h-28 w-28">
              {userPic ? (
                <img
                  src={URL.createObjectURL(userPic)}
                  alt="upload"
                  className="flex items-center m-auto h-full w-full rounded-2xl cursor-pointer"
                />
              ) : (
                <img
                  src="assets/documentupload.svg"
                  alt="upload"
                  className="m-auto cursor-pointer"
                  onClick={handleClick}
                />
              )}

              <input
                type="file"
                ref={userInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={handleFileChange}
                accept="image/*" // Accept only image files
              />
            </div>
          </div> */}

          <ImageUpload
            thumbnail={userPic}
            setThumbnail={setUserPic}
            label={"Reference Picture or Company Logo"}
            required={true}
          />

          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <InputField
              label="Reference First and Last Name"
              placeholder="User name"
              onChange={(e) => setUserName(e.target.value)}
              width={"w-full"}
              optional={false}
            />
            <InputField
              label="Role or Title"
              placeholder="Add role"
              onChange={(e) => setRole(e.target.value)}
              width={"w-full"}
              optional={false}
            />
            <InputField
              label="LinkedIn URL"
              placeholder="Add LinkedIn URL"
              onChange={(e) => setLinkedInUrl(e.target.value)}
              optional={true}
              width={"w-full"}
            />
          </div>

          <div className="flex flex-col gap-4">
            <div className="font-light">
               Reference Quote{" "}
            </div>
            <div className="flex items-center rounded-lg border border-grey-7 h-12">
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full"
                placeholder={"Description"}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div>
              Company Name<span className="text-orange-1">*</span>
            </div>

            <div className="xs:flex-col lg:flex w-full gap-4">
              {/* <div className="flex items-center rounded-lg bg-light-sea-green-1 h-12 w-14">
                {companyPic ? (
                  <img
                    src={URL.createObjectURL(companyPic)}
                    alt="upload"
                    className="flex items-center m-auto h-full w-full rounded-2xl cursor-pointer"
                  />
                ) : (
                  <img
                    src="assets/documentupload.svg"
                    alt="upload"
                    className="m-auto h-5 w-5 cursor-pointer"
                    onClick={handleCompanyPicClick}
                  />
                )}
                <input
                  type="file"
                  ref={companyInputRef}
                  style={{ display: "none" }} // Hide the file input
                  onChange={handleCompanyFileChange}
                  accept="image/*" // Accept only image files
                />
              </div> */}

              <div className="flex items-center rounded-lg border border-grey-7 h-12 w-full mt-3 lg:mt-0">
                <input
                  className="focus:border-transparent outline-none mx-5 w-full h-full"
                  placeholder="Company name"
                  onChange={(e) => setCompanyName(e.target.value)}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Save changes button */}

      <div className="flex flex-row-reverse">
        <div className="flex lg:justify-end w-full lg:w-2/12">
          <button
            className="btn-sea-green text-xl w-full"
            onClick={submitHandler}
          >
            Add
          </button>
        </div>
      </div>
    </form>
  );
}

export default ReferencesAdd;
