import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  removeOnBoardCookie,
  removeToken,
  setOnBoardCookie,
  setCompanyCookie,
  setToken,
} from "../../../auth/cookies";
import useFirebaseAuth from "../../../auth/useFirebaseAuth";
import { StateContext } from "../../../Context/StateContext";
import { getMethod, getMethodTokenless } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";

function ChooseCompanyType({ token }) {
  const { setLoading, setShowTrialModal } = useContext(StateContext);
  const navigate = useNavigate();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
  };

  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [companyType, setCompanyType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [ownerName, setOwnerName] = useState("");

  const { forgotPassword } = useFirebaseAuth();

  const submitHandler = (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    function parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    }

    let parseToken = parseJwt(token);
    console.log("PARSE : ", parseToken);

    var raw = JSON.stringify({
      ownerName: ownerName,
      name: companyName,
      companyTypeId: companyType,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL + "auth/register", requestOptions)
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((result) => {
        if (result.status) {
          removeToken();
          removeOnBoardCookie()
          setToken(token);
          setOnBoardCookie(token);
          setCompanyCookie(result);
          setShowTrialModal(!result?.company?.isTrialBannerShown)

          toastSuccess("Registration successful");
          navigate("/company?activePage=dashboard");
        } else {
          toastError(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        toastError(error.message);
      });
  };

  useEffect(() => {
    // setLoading(true);
    getMethodTokenless(`auth/getCompanyTypes`)
      .then((res) => {
        // setLoading(false);

        if (res?.status) {
          setCompanyTypeList(res?.types);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  return (
    <div className="modal-wrapper">
      <form
        className="flex flex-col bg-white rounded-2xl w-10/12 lg:w-1/3 p-10  gap-5"
        style={modalStyle}
        onSubmit={(e) => submitHandler(e)}
      >
        <div className="flex flex-row-reverse w-full"></div>
        <div className="font-semibold text-2xl">Select Company Type *</div>

        <div className="login-form-input-div">
          <input
            className="login-form-input-field"
            placeholder="Company Name"
            name="Company Name"
            required={true}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div className="login-form-input-div">
          <input
            className="login-form-input-field"
            placeholder="Owner Name"
            name="Owner Name"
            required={true}
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
          />
        </div>

        <div className="login-form-input-div">
          <select
            className="login-form-input-field"
            placeholder="Company Name"
            name="companyName"
            required={true}
            value={companyType}
            onChange={(e) => setCompanyType(e.target.value)}
          >
            <option value="">Select an option</option>
            {companyTypeList?.map((item, index) => {
              return (
                <option value={item?.id} key={index}>
                  {item?.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="flex justify-center w-full">
          <button
            className={`text-white text-xl py-5 w-full rounded-xl bg-sea-green`}
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChooseCompanyType;
