import React, { useEffect, useState } from 'react'

const PromoCodes = ({ trialData, promoData, noHandler, fetchCouponApplied, isCouponAvailed }) => {

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1000",
    }
    const [applyTrial, setApplyTrial] = useState(false);
    const [applyPromo, setApplyPromo] = useState(false);

    useEffect(() => {
        // Disable scrolling when the modal is open
        document.body.style.overflow = 'hidden';

        // Re-enable scrolling when the modal is unmounted
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    return (
        <div className="modal-wrapper">

            <div className='flex flex-col gap-3 w-10/12 lg:w-1/3' style={modalStyle}>

                <button className='flex justify-end' onClick={noHandler}>

                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_7613_5695)">
                            <rect width="24" height="24" rx="12" fill="#181717" />
                            <path d="M7.2 17.744L12 12.944L16.8 17.744L17.744 16.8L12.944 12L17.744 7.2L16.8 6.256L12 11.056L7.2 6.256L6.256 7.2L11.056 12L6.256 16.8L7.2 17.744ZM12.004 24C10.3453 24 8.78533 23.6853 7.324 23.056C5.86356 22.4258 4.59289 21.5707 3.512 20.4907C2.43111 19.4116 1.57556 18.1422 0.945333 16.6827C0.315111 15.2231 0 13.6636 0 12.004C0 10.3453 0.314667 8.78533 0.944 7.324C1.57422 5.86356 2.42933 4.59289 3.50933 3.512C4.58844 2.43111 5.85778 1.57556 7.31733 0.945333C8.77689 0.315111 10.3364 0 11.996 0C13.6547 0 15.2147 0.314667 16.676 0.944C18.1364 1.57422 19.4071 2.42933 20.488 3.50933C21.5689 4.58844 22.4244 5.85778 23.0547 7.31733C23.6849 8.77689 24 10.3364 24 11.996C24 13.6547 23.6853 15.2147 23.056 16.676C22.4258 18.1364 21.5707 19.4071 20.4907 20.488C19.4116 21.5689 18.1422 22.4244 16.6827 23.0547C15.2231 23.6849 13.6636 24 12.004 24Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_7613_5695">
                                <rect width="24" height="24" rx="12" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                </button>

                <div className='bg-[#EEF9FB] flex w-full rounded-2xl box-shadow1 m-auto justify-center p-5'>

                    {
                        !trialData?.available && !promoData?.available ?
                        <div className='p-5 bg-white rounded-2xl w-full h-40 flex justify-center items-center font-semibold text-3xl text-grey-2'>
                  No offers currently available!
                        </div>
                          :

                    <div className='flex flex-col gap-5 w-full'>

                        {/* Trial Card */}

                        {
                            trialData?.available &&
                            <div class="relative bg-white rounded-lg p-6 mx-auto w-full">

                                <div className='flex flex-col md:flex-row gap-5 md:gap-0 justify-between w-full'>

                                    <div className='flex flex-col gap-5'>
                                        <div class="flex gap-2">
                                            <div class="bg-teal-300 text-white rounded-full w-8 h-8 flex items-center justify-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <path d="M28.5337 19L19.0003 28.5333C18.7337 28.8 18.4337 29 18.1003 29.1333C17.767 29.2666 17.4337 29.3333 17.1003 29.3333C16.767 29.3333 16.4337 29.2666 16.1003 29.1333C15.767 29 15.467 28.8 15.2003 28.5333L3.43366 16.7666C3.18921 16.5222 3.00033 16.2391 2.86699 15.9173C2.73366 15.5955 2.66699 15.2564 2.66699 14.9V5.33329C2.66699 4.59996 2.92833 3.9724 3.45099 3.45063C3.97366 2.92885 4.60121 2.66751 5.33366 2.66663H14.9003C15.2559 2.66663 15.6003 2.73907 15.9337 2.88396C16.267 3.02885 16.5559 3.22307 16.8003 3.46663L28.5337 15.2333C28.8003 15.5 28.995 15.8 29.1177 16.1333C29.2403 16.4666 29.3012 16.8 29.3003 17.1333C29.2994 17.4666 29.2385 17.7946 29.1177 18.1173C28.9968 18.44 28.8021 18.7342 28.5337 19ZM8.66699 10.6666C9.22255 10.6666 9.69499 10.4724 10.0843 10.084C10.4737 9.69552 10.6679 9.22307 10.667 8.66663C10.6661 8.11018 10.4719 7.63818 10.0843 7.25063C9.69677 6.86307 9.22433 6.6684 8.66699 6.66663C8.10966 6.66485 7.63766 6.85952 7.25099 7.25063C6.86433 7.64174 6.66966 8.11374 6.66699 8.66663C6.66433 9.21951 6.85899 9.69196 7.25099 10.084C7.64299 10.476 8.11499 10.6702 8.66699 10.6666ZM17.3337 23.3333L22.0003 18.6666C22.2448 18.4222 22.4394 18.1333 22.5843 17.8C22.7292 17.4666 22.8012 17.1111 22.8003 16.7333C22.8003 15.9777 22.5337 15.3333 22.0003 14.8C21.467 14.2666 20.8225 14 20.067 14C19.6448 14 19.2283 14.1222 18.8177 14.3666C18.407 14.6111 17.9123 15.0222 17.3337 15.6C16.667 14.9777 16.1448 14.5555 15.767 14.3333C15.3892 14.1111 15.0003 14 14.6003 14C13.8448 14 13.2003 14.2666 12.667 14.8C12.1337 15.3333 11.867 15.9777 11.867 16.7333C11.867 17.1111 11.9394 17.4666 12.0843 17.8C12.2292 18.1333 12.4234 18.4222 12.667 18.6666L17.3337 23.3333Z" fill="#1CBAAB" />
                                                </svg>
                                            </div>
                                            <div className='flex flex-col gap-5'>

                                                <div className='flex flex-col'>
                                                    <h3 class="text-lg font-semibold text-gray-800">{trialData?.title}</h3>
                                                    <p class="text-grey-2 text-md">{trialData?.name}</p>
                                                </div>

                                                <div class="text-sm text-grey-9">*Terms and conditions apply</div>
                                            </div>
                                        </div>



                                    </div>

                                    <div className='w-1/4'>

                                        <div className='m-auto md:w-full'>
                                            <button className={`${isCouponAvailed?.trial === true ? "bg-grey-8 cursor-not-allowed" : "bg-sea-green"} rounded-lg text-white p-2 md:py-3 w-full`}
                                                onClick={() => {
                                                    fetchCouponApplied("trial"); noHandler()
                                                }}>Apply</button>
                                        </div>

                                    </div>

                                </div>


                                <div class="border-t border-dashed border-teal-300 my-4"></div>

                                <p class="text-center text-sm text-gray-600">This offer is exclusively for you</p>



                                <div class="absolute -left-4 top-1/2 -translate-y-1/2 w-8 h-8 bg-[#EEF9FB] rounded-full"></div>
                                <div class="absolute -right-4 top-1/2 -translate-y-1/2 w-8 h-8 bg-[#EEF9FB] rounded-full"></div>
                            </div>
                        }


                        {/* Promo Card */}
                        {
                            promoData?.available &&
                            <div className='flex flex-col md:flex-row gap-5 md:gap-0 bg-white p-4 rounded-xl justify-between'>

                                <div className='flex flex-col md:w-2/3'>

                                    <div className='flex flex-col gap-5'>

                                        <div className='flex gap-2'>

                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <g clip-path="url(#clip0_7870_1713)">
                                                        <path d="M26.7209 11.6133V5.33326H20.4542L16.0009 0.919922L11.6142 5.33326H5.33423V11.6133L0.920898 15.9999L5.33423 20.4533V26.7199H11.6142L16.0009 31.1333L20.4542 26.7199H26.7209V20.4533L31.1342 15.9999L26.7209 11.6133ZM11.7742 9.39992C13.0809 9.39992 14.1342 10.4533 14.1342 11.7733C14.1342 12.3992 13.8856 12.9994 13.443 13.442C13.0004 13.8846 12.4001 14.1333 11.7742 14.1333C10.4542 14.1333 9.4009 13.0799 9.4009 11.7733C9.4009 10.4533 10.4542 9.39992 11.7742 9.39992ZM20.2942 22.6666C18.9876 22.6666 17.9342 21.5999 17.9342 20.2933C17.9342 19.6673 18.1829 19.0671 18.6255 18.6245C19.068 18.1819 19.6683 17.9333 20.2942 17.9333C21.6009 17.9333 22.6676 18.9866 22.6676 20.2933C22.6676 20.9227 22.4175 21.5264 21.9724 21.9715C21.5273 22.4165 20.9237 22.6666 20.2942 22.6666ZM11.3342 22.7066L9.33423 20.7066L20.7076 9.33326L22.7076 11.3333L11.3342 22.7066Z" fill="#1CBAAB" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_7870_1713">
                                                            <rect width="32" height="32" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>

                                            <div className='flex flex-col w-full'>

                                                <div className='text-sm md:text-xl font-medium'>

                                                    {promoData?.title}
                                                </div>
                                                <div className='text-grey-2'>

                                                    {promoData?.name}
                                                </div>
                                            </div>


                                        </div>

                                        {/* <div className='text-sm'>
                                        *Only applicable for 1st time user
                                    </div> */}
                                    </div>
                                </div>

                                <div className='w-1/4'>

                                    <div className='m-auto md:w-full'>
                                        <button className={`${isCouponAvailed?.promo === true ? "bg-grey-8 cursor-not-allowed" : "bg-sea-green"} rounded-lg text-white p-2 md:py-3 w-full`} onClick={() => {

                                            fetchCouponApplied("promo"); noHandler()
                                        }
                                        }
                                            disabled={isCouponAvailed?.promo === true}>Apply</button>
                                    </div>

                                </div>

                            </div>
                        }


                    </div>
                    }


                </div>
            </div>

        </div >
    );
}

export default PromoCodes