import React, { useState } from "react";
import { getCompanyUserCookie } from "../../../../auth/cookies";
import DeleteConfirmation from "../../modals/DeleteConfirmation";

function Executives({
  name,
  designation,
  image,
  linkedin,
  handleDelete,
  id,
  onEdit,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const userRole = getCompanyUserCookie('userRole')

  const handleDeleteClick = () => {
    // console.log("ID in product compo: ", id)
    // handleDelete(id)
    setShowDeleteModal(true);
  };
  return (
    <div
      key={id}
      className="flex flex-col gap-2 bg-white rounded-md p-3 w-full"
      style={{ boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.07)" }}
    >
      {/* DETAILS */}
    
        <div className="flex flex-col gap-2">
          {/* Image */}
          <div className="flex justify-between items-center">
            <div>

            <img
              src={image ? image : "/assets/image-not-found.png"}
              alt="title"
              className="h-[61px] w-[62px] rounded-full"
            />
            </div>

            {/* linkedIn */}
            <a href={linkedin} target="_blank">
              <div className="mx-auto flex justify-center items-center cursor-pointer">
                <div className="w-10 h-10 rounded-full bg-light-sea-green flex items-center jusify-center">
                  <img
                    src="assets/linkedin.svg"
                    alt="title"
                    className="w-14 h-14 object-contain"
                  />
                </div>
              </div>
            </a>
          </div>

          {/* Name and description */}
          <div className="flex flex-col w-7/12">
            <div className="text-lg font-semibold overflow-hidden text-ellipsis whitespace-nowrap capitalize">
              {name}
            </div>
            <div className="text-grey-9 font-light text-xs overflow-hidden text-ellipsis whitespace-nowrap">
              {designation}
            </div>
          </div>
        </div>

        {/* <div className="w-4/12 h-0 rounded-full overflow-hidden" style={{paddingBottom: "25%", backgroundRepeat:"no-repeat",
                backgroundImage: `url(${image ? image : "assets/ProfilePic.png"})`, backgroundSize:"cover", backgroundPosition: "center"}}>
        </div> */}
  

      {/* ACTION BUTTONS */}
      {
        userRole !== "viewer" &&
      <div className="flex items-center justify-end gap-2">
        <div className="cursor-pointer w-10 h-10">
          <button
            
            onClick={() => onEdit(true, id)}
          >
            <img src="/assets/edit.svg" alt="edit" />
          </button>
        </div>
        <div
          
          className="cursor-pointer w-10 h-10"
          
        >
          <button onClick={handleDeleteClick}>

          <img src="/assets/trash.svg" alt="delete" />
          </button>
        </div>
      </div>
      }
      {showDeleteModal && (
        <DeleteConfirmation
          setShowModal={setShowDeleteModal}
          deleteHandler={() => handleDelete(id)}
        />
      )}
    </div>
  );
}

export default Executives;
