import React from 'react'
import { useNavigate } from 'react-router-dom';

export const Failure = () => {
     const navigate = useNavigate();
  return (
    <div className='h-screen flex justify-center'>

    <div className='flex flex-col gap-10 lg:gap-16 items-center m-auto justify-center w-2/3 text-center lg:w-2/5 shadow-xl p-5 h-2/3'>

        <div className='flex flex-col items-center gap-3'>

            <div className='w-1/3'>
               <img src="/assets/Failure.gif"/>
            </div>

            <div className='font-bold text-[32px]'>
                Payment Failed
            </div>
            <div className='text-grey-2 text-xl'>
                Please try again. Something went wrong
            </div>


        </div>

        <div className='w-3/4'>
            <button className='bg-sea-green text-white rounded-xl px-5 py-4 w-full' onClick={()=>navigate("/subscribe-now")}>Continue</button>
        </div>


    </div>
    </div>
  )
}
