import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getCompanyCookie, getOnBoardCookie, setOnBoardCookie } from "../../../auth/cookies";
import useFirebaseAuth from "../../../auth/useFirebaseAuth";
import { StateContext } from "../../../Context/StateContext";
import { postMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";

function Password({onEditClick}) {

  const navigate = useNavigate()

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const SubmitHandler = (e) => {
        e.preventDefault();
        var raw = JSON.stringify({
            oldPassword : oldPassword,
            newPassword: newPassword
        })

        postMethod(`company/changePassword`, raw)
        .then((res) => {
        //   setLoading(false);
          if (res.status === true) {
            // setLoading(false);
            setOldPassword("");
            setConfirmPassword("");
            setNewPassword("");
            toastSuccess(res.message);
            setTimeout(() => {
              navigate("/")
            }, 2000);
          } else {
            toastError(res.message);
          }
        })
        .catch((error) => {
        //   setLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });

    }
    useEffect(() => {
      window.scrollTo(0,0)
    }, [])
    return (
      <form className="p-5 lg:p-8 category-content-wrapper-div gap-5" onSubmit={SubmitHandler}>
           {/* header */}
           <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
  
                  <div className="flex flex-col">
                      <div className="font-medium text-[28px]">
                          Password
                      </div>
                      <div className="text-grey-2 text-[16px]">
                          Change your password
                      </div>
                  
                  
                  </div>
                  <div className="flex lg:justify-end mt-5 lg:mt-0">
                      <button className="btn-sea-green-thick" type="submit">
                     Change password
                      </button>
                  </div>
  
          </div>

          {/* Body */}
          <div className="flex flex-col gap-7">

                <div className="flex flex-col gap-4">
                    <div className="font-semibold">
                        Old password
                    </div>
                    <div className="flex items-center rounded-lg border border-grey-7 h-12">
                        <input 
                        className="focus:border-transparent outline-none mx-5 w-full h-full"
                        type="text"
                        required={true}
                         value={oldPassword}
                         onChange={(e) => {
                           setOldPassword(e.target.value);
                        //    setIsOldPassCorrect(true);
                         }}
                        />
                    </div>
                    {/* {isOldPassCorrect === "" || isOldPassCorrect ? (
                    ""
                  ) : (
                    <p className="text-red m-0">Password is incorrect.</p>
                  )} */}

                </div>
                <div className="flex flex-col gap-4">
                    <div className="font-semibold">
                        New password
                    </div>
                    <div className={`flex items-center rounded-lg border border-grey-7 h-12 ${
                         oldPassword.trim().length > 0
                         ? ""
                         : "bg-grey-5 cursor-disabled"
                    }`}>
                        <input className={`focus:border-transparent outline-none mx-5 w-full h-full
                        ${
                            oldPassword.trim().length > 0
                         ? ""
                         : "bg-grey-5 cursor-disabled"
                        }`}
                        type="text"
                        value={newPassword}
                        required={true}
                        readOnly={!(oldPassword.trim().length > 0)}
                        onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>

                </div>
                <div className="flex flex-col gap-4">
                    <div className="font-semibold">
                        Confirm password
                    </div>
                    <div className={`flex items-center rounded-lg border border-grey-7 h-12
                    ${
                        oldPassword.trim().length > 0
                     ? ""
                     : "bg-grey-5 cursor-disabled"
                    }`}>
                        <input 
                        className={`focus:border-transparent outline-none mx-5 w-full h-full
                        ${
                            oldPassword.trim().length > 0
                         ? ""
                         : "bg-grey-5 cursor-disabled"
                        }`}
                        value={confirmPassword}
                        required={true}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>

                </div>

          </div>
      </form>
    );
  }
  
  export default Password;