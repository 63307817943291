import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../Context/StateContext";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import {
  getMethod,
  postFormMethod,
  postMethod,
} from "../../../utils/apiMethod";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { TextEditor } from "../../general/TextEditor";
import ImageUpload from "../../general/ImageUpload";
import NotificationConfirmation from "../../general/modals/NotificationConfirmation";
import { SendNewsNotification } from "../../../utils/notificationApis";

function NewsEdit({ onBackClick, newsId, openNotificationModal, setOpenNotificationModal }) {
  const { setLoading, profileStatus } = useContext(StateContext);
  const [notificationModal, setNotificationModal] = useState(false)
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [publisher, setPublisher] = useState("");
  const [description, setDescription] = useState("");
  const [newsDetails, setNewsDetails] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [publishedOn, setPublishedOn] = useState("")

  // Quill modules and formats configuration
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  // Quill formats
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const submitHandler = (e) => {

    e.preventDefault();
    if(description?.length <= 11){
     toastError("Description can't be empty")
    }else{

      setLoading(true);

      let formdata = new FormData();
      formdata.append("newsId", newsId);
      if (newsDetails?.author !== author) {
        formdata.append("author", author);
      }
      if (newsDetails?.publisher !== publisher) {
        formdata.append("publisher", publisher);
      }
      if (newsDetails?.title !== title) {
        formdata.append("title", title);
      }
      if (newsDetails?.description !== description) {      
          formdata.append("description", description);
      }
       if (selectedFile&&(newsDetails?.image !== selectedFile)) {
         formdata.append("image", selectedFile);
       }
       if(publishedOn){
        formdata.append("publishingDate", publishedOn);
       }
      
        postFormMethod(`news/updateNews`, formdata)
          .then((res) => {
            setLoading(false)
            if (res?.status == true) {
                toastSuccess(res?.message);
                
                  if(profileStatus === "2") {
                    setNotificationModal(openNotificationModal)
                }else{
                   onBackClick();
                }
                if(!openNotificationModal){

                  onBackClick();
                }
            }
            else{
              toastError(res.message)
            }
          })
          .catch((error) => {
            setLoading(false);
            toastError(error?.message);
            console.log("error.message: ", error?.message);
          });

     
    }
   
  };

  useEffect(() => {
    setLoading(true);
    
      window.scrollTo(0,0)
 
    getMethod(`news/viewNews/${newsId}`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          console.log(res);
          setNewsDetails({ ...res.data });
          setSelectedFile(res?.data?.image);
          setAuthor(res.data.author);
          setPublisher(res.data.publisher);
          setTitle(res.data.title);
          setDescription(res.data.description);
          setPublishedOn(res?.data?.publishingDate?.slice(0, 10));
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  return (
    <form
      className="p-5 lg:p-8 category-content-wrapper-div"
      onSubmit={submitHandler}
    >
      {/*  Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={onBackClick} className="cursor-pointer">
              <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px]">Edit News</div>
              <div className="text-grey-2">Edit New Article</div>
            </div>
          </div>

          <div className="flex lg:justify-end mt-5 lg:mt-0">
            <button className="btn-sea-green-thick" type="submit">
              Save
            </button>
          </div>
        </div>
      </div>

      {/* Form */}

      <div className="flex flex-col gap-5">
        {/* COMPANY DETAILS */}

        {/* Testimonials */}
        <div className="flex flex-col form-outline-wrapper-div p-8 gap-5">
          <ImageUpload
            thumbnail={selectedFile}
            setThumbnail={setSelectedFile}
            label={"News Thumbnail"}
            required={true}
          />
          <div className="flex flex-col gap-5">
            <div>
              Title<span className="text-orange-1">*</span>
            </div>

            <div className="flex rounded-lg border border-grey-7 p-3 gap-2">
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full"
                placeholder="Add Title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required={true}
              />
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div>
              Author<span className="text-orange-1">*</span>
            </div>

            <div className="flex rounded-lg border border-grey-7 p-3 gap-2">
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full"
                placeholder="Add Author"
                type="text"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                required={true}
              />
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div>
              Publisher<span className="text-orange-1">*</span>
            </div>

            <div className="flex rounded-lg border border-grey-7 p-3 gap-2">
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full"
                placeholder="Add Publisher"
                type="text"
                value={publisher}
                onChange={(e) => setPublisher(e.target.value)}
                required={true}
              />
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div>
              Published On<span className="text-orange-1">*</span>
            </div>

            <div className="flex rounded-lg border border-grey-7 p-3 gap-2">
              <input
                className="focus:border-transparent outline-none mx-5 w-full h-full"
                type="date"
                value={publishedOn}
                onChange={(e) => setPublishedOn(e.target.value)}
                required={true}
              />
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div>
              Description<span className="text-orange-1">*</span>
            </div>

            {/*                
                    <div className="flex rounded-lg border border-grey-7 p-3 gap-2">
                        <input
                        className="focus:border-transparent outline-none mx-5 w-full h-full"
                        placeholder="Add Description"
                        type='text'
                        value={description}
                        onChange={e=> setDescription(e.target.value)}
                        />
                        
                    </div> */}

            {/* <div>
              <ReactQuill
                theme="snow" // Use the "snow" theme for a cleaner interface
                value={description}
                onChange={setDescription}
                modules={modules}
                formats={formats}
                style={{ height: "150px" }} // Set the height of the editor
              />
            </div> */}
            <TextEditor
              placeholder={"Add description"}
              value={description}
              setValue={setDescription}
            />
          </div>
        </div>
      </div>

      {/* Save changes button */}

      <div className="flex flex-row-reverse">
        <div className="flex lg:justify-end w-full lg:w-2/12">
          <button className="btn-sea-green text-xl w-full" type="submit">
            Save
          </button>
        </div>
      </div>

      {notificationModal && (
        <NotificationConfirmation
          yesHandler = {() => {
            SendNewsNotification(newsId);
            setNotificationModal(false);
            onBackClick();
          }
          }
          noHandler = {() => {
            setNotificationModal(false);
            onBackClick();
          }
          }
          keyword={"article"}
        />
      )}
    </form>
  );
}

export default NewsEdit;
