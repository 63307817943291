import React, { useState } from 'react'
import { getCompanyUserCookie } from '../../../../auth/cookies';
import DeleteConfirmation from '../../modals/DeleteConfirmation';

function Investors({name, image, handleDelete, id, onEdit}) {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const userRole = getCompanyUserCookie('userRole');

    const handleDeleteClick = () => {
        // console.log("ID in product compo: ", id)
        // handleDelete(id)

        setShowDeleteModal(true)
      };

  return (
    <div key={id} className="flex flex-col gap-5 bg-white rounded-md p-3 w-full" style={{"boxShadow": "0px 4px 14px 0px rgba(0, 0, 0, 0.07)"}}>
    {/* DETAILS */}
    <div className="flex flex-col items-start gap-5">
        {/* Image */}
        <div className="h-[61px] w-[62px] ">
            <img src={image?image:"/assets/image-not-found.png"} alt="title" className="h-full w-full rounded-full"/>
        </div>
        {/* <div className="w-4/12 h-0 rounded-full overflow-hidden" style={{paddingBottom: "25%", backgroundRepeat:"no-repeat",
            backgroundImage: `url(${image ? image : "assets/ProfilePic.png"})`, backgroundSize:"cover", backgroundPosition: "center"}}>
        </div> */}

        {/* Name and description */}
        <div className="flex flex-col w-full">

            <div className="text-lg font-semibold overflow-hidden text-ellipsis whitespace-nowrap capitalize">
             {name}
            </div>
           
        </div>

        {/* linkedIn */}
       
    </div>

    {/* ACTION BUTTONS */}
    {
      userRole !== "viewer" &&
    <div className="flex items-center justify-end gap-2">
      
            <button className=""
            onClick={() => onEdit(true, id)}>
          <img src="/assets/edit.svg" alt="edit"/>
            </button>
        
        <div className="flex justify-center cursor-pointer" >
            <button onClick={handleDeleteClick}>

            <img src="/assets/trash.svg" alt="delete"/>
            </button>


        </div>
    </div>
    }
    {showDeleteModal && (
        <DeleteConfirmation
          setShowModal={setShowDeleteModal}
          deleteHandler={() => handleDelete(id)}
        />
      )}
</div>
  )
}

export default Investors
