import React, { useContext, useEffect, useState } from 'react'
import { getMethod } from '../../../utils/apiMethod'
import { StateContext } from '../../../Context/StateContext';
import { useNavigate } from 'react-router-dom';
import InvoiceData from './InvoiceData';
import { toastError, toastSuccess } from '../../../utils/Toasters';
import PaymentDetails from './PaymentDetails';

const Subscription = () => {
  const [data, setData] = useState({})
  const [category, setCategory] = useState("invoice")
  const navigate = useNavigate();
  const { setLoading, setCancelPlanLoading } = useContext(StateContext);
  const getData = async () => {
    setLoading(true)
    const response = await getMethod('subscription/companySubscriptionDetails');
    setData(response?.data)
    setLoading(false);

  }

  const cancelPlan = async () => {
    setCancelPlanLoading(true);
    const response = await getMethod('subscription/cancelSubscription');
    if (response?.status === true) {
      toastSuccess(response?.message);
    } else {
      toastError(response?.message)
    }
    await getData();
    setCancelPlanLoading(false)
  }
  const removeCancellation = async () => {
    setCancelPlanLoading(true);
    const response = await getMethod('subscription/removeScheduledCancellation');
    if (response?.status === true) {
      toastSuccess(response?.message);
    } else {
      toastError(response?.message)
    }
    await getData();
    setCancelPlanLoading(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getData()
  }, [])

  return (
    <div className='flex flex-col gap-5'>

      <div className="p-5 lg:p-8 category-content-wrapper-div gap-5">
        {/* header */}
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">

          <div className="flex flex-col">
            <div className="font-medium text-[28px]">
              Subscription and Billing Management
            </div>

            <div className="text-grey-2 text-md leading-[18px]">
              Manage your subscription plans, trials, payment methods, and invoices from one place
            </div>

          </div>

          {
            data?.currentSubscriptionStatus === "trial" || data?.currentSubscriptionStatus === "active" || data?.currentSubscriptionStatus === "non_renewing" ? "" :
              <div className="flex lg:justify-end mt-5 lg:mt-0">
                <button className="btn-sea-green-thick" onClick={() => navigate('/subscribe-now')}>
                  Explore Plans
                </button>
              </div>
          }

        </div>

        <div className='flex'>

          <div className='font-normal text-md w-1/3'>
            Current Trial
          </div>


          {
            data?.trialStartDate && data?.trialEndDate ?
              <div className='font-normal text-md w-1/3 flex flex-col gap-1'>
                <div className='font-normal text-lg mb-3 flex gap-3'>

                  {data?.trialTitle} <div className={`${data?.trialStatus === "Active" ? "bg-sea-green" : "bg-red"} text-white rounded-full px-2 text-xs flex items-center`}>{data?.trialStatus}</div>
                </div>
                <div className='font-normal text-md text-grey-2'>

                  Start Date : {data?.trialStartDate}
                </div>
                <div className='font-normal text-md text-grey-2'>
                  Expiry Date : {data?.trialEndDate}
                </div>
              </div>
              :
              <div className='font-normal text-md w-1/3 flex flex-col gap-1'>
                N/A
              </div>

          }


        </div>

        <div className='flex'>

          <div className='font-normal text-md w-1/3'>
            Current Subscription Plan
          </div>
          <div className='font-normal text-md w-1/3 flex flex-col gap-1'>
          {
            data?.subscriptionStartDate && data?.subscriptionEndDate ?
                <>
                <div className='font-normal text-lg mb-3 flex gap-3 capitalize'>

                  {data?.subscriptionTitle} <div className={`${data?.chargebeeSubscriptionStatus === "active" ? "bg-sea-green" : data?.chargebeeSubscriptionStatus === "in_trial" ? "bg-yellow-1" : data?.chargebeeSubscriptionStatus === "non_renewing" ? "bg-red" : "bg-dark-blue-1"} text-white rounded-full px-2 text-xs flex items-center`}>{data?.chargebeeSubscriptionStatus.replace(/_/g, ' ').toLowerCase()}</div>
                </div>
                <div className='font-normal text-md text-grey-2'>

                  Start Date : {data?.subscriptionStartDate}
                </div>
                <div className='font-normal text-md text-grey-2'>
                  Expiry Date : {data?.subscriptionEndDate}
                </div>
                </>
              :
               "N/A"
            

          }
              </div>


          {
            data?.currentSubscriptionStatus === "futr_trial" ? "" :
              data?.currentSubscriptionStatus === "active" || data?.currentSubscriptionStatus === "trial" ?
                <div className='text-sea-green text-lg text-end w-1/3 font-semibold font-poppins' role='button' onClick={cancelPlan}>
                  Cancel Plan
                </div>
                : data?.currentSubscriptionStatus === "non_renewing" ?
                  <div className='text-sea-green text-lg text-end w-1/3 font-semibold font-poppins' role='button' onClick={removeCancellation}>
                    Remove Scheduled Cancellation
                  </div> : ""
          }

        </div>
        <div className='flex'>

          <div className='font-normal text-md w-1/3'>
            Payment Method
          </div>
          <div className='w-1/3'>

          {
            data?.currentSubscriptionStatus === "futr_trial" ?
              <div className='font-normal text-md w-full flex flex-col gap-1'>
                N/A
              </div>
              :
              data?.primaryPaymentMethod
                ? <div className='flex flex-col gap-3 w-full'>

                  {Object.entries(data?.primaryPaymentMethod).map(([key, value]) => (
                    <div className="flex gap-2 break-words flex-wrap" key={key}>
                      <div className="font-bold">{key}:</div>
                      <div className='max-w-full break-words'>{value}</div>
                    </div>
                  ))}


                </div> : "N/A"



          }
          </div>

          <div className='w-1/3'>

            {
              data?.currentSubscriptionStatus === "futr_trial" ? "" :
                <div className='flex justify-end w-full'>
                  <a href={data?.manageSubscriptionLink} target='_blank'>

                    <button className='text-sea-green font-semibold font-poppins border border-sea-green px-5 py-3 rounded-lg'>
                      Manage Subscription & Payment Mode
                    </button>
                  </a>
                </div>
            }
          </div>


        </div>

      </div>

      <div className='flex flex-col gap-5'>

        <div className="flex justify-between">
          <div className="flex gap-10 text-sea-green font-bold text-lg">
            <div
              className={`py-2 cursor-pointer ${category === "invoice" && "border-b-4 border-sea-green"
                }`}
              onClick={() => setCategory("invoice")}
            >
              Invoice Details
            </div>
            <div
              className={`py-2 cursor-pointer ${category === "payment" && "border-b-4 border-sea-green"
                }`}
              onClick={() => setCategory("payment")}
            >
              Payment Details
            </div>
          </div>
        </div>

      </div>

      {
        {
          invoice: <InvoiceData />,
          payment: <PaymentDetails />,
        }[category]
      }



    </div>
  )
}

export default Subscription