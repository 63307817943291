// import icons
import { useContext, useEffect, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { getMethod } from "../../utils/apiMethod";
import { StateContext } from "../../Context/StateContext";
import { removeCompanyUserCookie, removeCompanyCookie, removeOnBoardCookie, removeToken, getCompanyUserCookie, getCompanyCookie } from "../../auth/cookies";
import { toastError, toastInfo } from "../../utils/Toasters";
import { useNavigate } from "react-router";
import useFirebaseAuth from "../../auth/useFirebaseAuth";
import LogoutConfirmation from "./modals/LogoutConfirmation";

function HeaderMobileMode({fetchActiveCategory, currentCategory, activePage}) {
  const { setLoading } = useContext(StateContext);

  const {
    setCompanyOwnerName,
    setCompanyName,
    setCompanyLogo,
    setCompanyBio,
    refreshHeader,
  } = useContext(StateContext);


  const name = getCompanyUserCookie("userName") ? getCompanyUserCookie("userName") : getCompanyUserCookie("ownerName")
  const [thumbnail, setThumbnail] = useState(null);


  const {signOut} = useFirebaseAuth();
  const signInProvider = getCompanyCookie("firebaseSignInProvider");
  const userRole = getCompanyUserCookie('userRole')
  const [openHeaderModal, setOpenHeaderModal] = useState(false)

  const [activeCategory, setActiveCategory] = useState(activePage);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const navigate = useNavigate();

  function handleLogout() {
    removeCompanyCookie();
    removeCompanyUserCookie();
    removeToken();
    removeOnBoardCookie();
    signOut();

    toastInfo("User logged out!");
    navigate("/");
  }
  const manageCategories = [
    {
      item: "Company Profile",
      icon: "office-building",
      value: "dashboard",
    },
    {
      item: "Products",
      icon: "products",
      value: "products",
    },
    {
      item: "People",
      icon: "people",
      value: "people",
    },
    {
      item: "Finance",
      icon: "finance",
      value: "finance",
    },
    {
      item: "Metrics",
      icon: "metrics",
      value: "metrics",
    },
    {
      item: "Content",
      icon: "content",
      value: "content",
    },
    {
      item: "News",
      icon: "news",
      value: "news",
    },
    {
      item: "References",
      icon: "references",
      value: "references",
    },
    {
      item: "Sources",
      icon: "sources",
      value: "sources",
    },
    {
      item: "Awards",
      icon: "awards",
      value: "awards",
    },
    {
      item: "Analytics",
      icon: "analytics",
      value: "analytics",
    },
    {
      item: "AI Chatbot",
      icon: "chatbot",
      value: "chatbot",
    },
    {
      item: "Feedback",
      icon: "feedback",
      value: "feedback",
    },
  ];
  const securityCategories = [
    {
      item: "Password",
      icon: "password",
      value: "password",
    },
    {
      item: "Privacy",
      icon: "privacy",
      value: "privacy",
    },
    {
      item: "Profile Management",
      icon: "profileManagement",
      value: "profileManagement",
    },
    {
      item: "Subscription",
      icon: "subscription",
      value: "subscription",
    },
  ]

  useEffect(() => {
    setActiveCategory(activePage);
  }, []);

  useEffect(() => {
    getMethod(`company/getMyCompanyDetails`)
      .then((res) => {
        // setLoading(false);

        if (res?.status) {
          console.log(res);

          // setName(res?.company?.user?.name);
          setThumbnail(res?.company?.company?.ownerImageUrl);

          // context variables
          // setCompanyOwnerName(res?.company?.company?.ownerName);
          setCompanyName(res?.company?.company?.name);
          setCompanyLogo(res?.company?.company?.thumbnailUrl);
          setCompanyBio(res?.company?.company?.description);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        // setLoading(false);

        console.log("error.message: ", error.message);
      });
  }, [refreshHeader]);

  return (
    <>

      {/* Mobile View */}

      <div className="bg-white fixed top-0 left-0 w-full z-50 lg:hidden">
        <div className="flex">
          <div className="w-1/4 bg-dark-blue-1 h-3"> </div>
          <div className="w-1/4 bg-aqua-1 h-3"> </div>
          <div className="w-1/4 bg-sea-green h-3"> </div>
          <div className="w-1/4 bg-yellow-1 h-3"> </div>
        </div>
        <div className="flex mt-3 mb-5">
          <div className="flex justify-between px-5 items-center w-full">


            

            {/* side panel modal */}
            <div className="relative" onClick={()=>setOpenHeaderModal(!openHeaderModal)}>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.91775 32.0824C5.42216 34.5834 9.447 34.5834 17.5001 34.5834C25.5532 34.5834 29.5797 34.5834 32.0807 32.0807C34.5834 29.5814 34.5834 25.5532 34.5834 17.5001C34.5834 9.447 34.5834 5.42046 32.0807 2.91775C29.5814 0.416748 25.5532 0.416748 17.5001 0.416748C9.447 0.416748 5.42046 0.416748 2.91775 2.91775C0.416748 5.42216 0.416748 9.447 0.416748 17.5001C0.416748 25.5532 0.416748 29.5814 2.91775 32.0824ZM29.0313 24.3334C29.0313 24.6732 28.8963 24.9991 28.6561 25.2394C28.4158 25.4797 28.0899 25.6147 27.7501 25.6147H7.25008C6.91027 25.6147 6.58438 25.4797 6.3441 25.2394C6.10382 24.9991 5.96883 24.6732 5.96883 24.3334C5.96883 23.9936 6.10382 23.6677 6.3441 23.4274C6.58438 23.1872 6.91027 23.0522 7.25008 23.0522H27.7501C28.0899 23.0522 28.4158 23.1872 28.6561 23.4274C28.8963 23.6677 29.0313 23.9936 29.0313 24.3334ZM27.7501 18.7813C28.0899 18.7813 28.4158 18.6463 28.6561 18.4061C28.8963 18.1658 29.0313 17.8399 29.0313 17.5001C29.0313 17.1603 28.8963 16.8344 28.6561 16.5941C28.4158 16.3538 28.0899 16.2188 27.7501 16.2188H7.25008C6.91027 16.2188 6.58438 16.3538 6.3441 16.5941C6.10382 16.8344 5.96883 17.1603 5.96883 17.5001C5.96883 17.8399 6.10382 18.1658 6.3441 18.4061C6.58438 18.6463 6.91027 18.7813 7.25008 18.7813H27.7501ZM29.0313 10.6667C29.0313 11.0066 28.8963 11.3324 28.6561 11.5727C28.4158 11.813 28.0899 11.948 27.7501 11.948H7.25008C6.91027 11.948 6.58438 11.813 6.3441 11.5727C6.10382 11.3324 5.96883 11.0066 5.96883 10.6667C5.96883 10.3269 6.10382 10.001 6.3441 9.76077C6.58438 9.52049 6.91027 9.3855 7.25008 9.3855H27.7501C28.0899 9.3855 28.4158 9.52049 28.6561 9.76077C28.8963 10.001 29.0313 10.3269 29.0313 10.6667Z"
              fill="#1CBAAB"
            />
          </svg>
        </div>

         {/* modal */}

         {

           openHeaderModal &&

           <div className="absolute flex flex-col gap-3 bg-white box-shadow1 px-10 py-5 rounded-xl h-[600px] overflow-y-scroll">
           <div className="text-grey-3 text-sm">Manage</div>
           <div className="flex flex-col">
             {manageCategories.map((category, index) => {
               return (
                 <div
                   key={index}
                   onClick={() => {
                     navigate(`/company?activePage=${category.value}`)
                     setActiveCategory(category.value);
                     fetchActiveCategory(category.item);
                   }}
                   className={`flex gap-2 items-center rounded-lg text-xs px-2 py-3 cursor-pointer ${
                     activePage === category.value
                       ? "bg-sea-green text-white"
                       : "bg-none"
                   } `}
                 >
                   <div>
                     <img
                       src={`assets/${category.icon}-${
                         activePage === category.value ? "white" : "black"
                       }.svg`}
                       alt={`${category.item}`}
                       className="min-w-[20px] min-h-[20px] max-w-[20px] max-h-[20px] object-fit"
                     />
                   </div>
                   <div>{category.item}</div>
                 </div>
               );
             })}
           </div>
           <div className="flex flex-col gap-3">
             <div className="text-grey-3 text-sm">Security</div>
             <div className="flex flex-col">
               {securityCategories.map((category, index) => {
                  if (category?.item === 'Password' && signInProvider !== 'custom') {
                    return null; // Do not render the password category
                  }
                  else if(category?.item === 'Profile Management' && userRole !== 'owner'){
                    return null;
                  }
                  else
                 return (
                   <div
                     key={index}
                     onClick={() => {
                       navigate(`/company?activePage=${category.value}`)
                       setActiveCategory(category.value);
                       fetchActiveCategory(category.item);
                     }}
                     className={`flex gap-2 items-center rounded-lg text-xs px-2 py-3 
                                           cursor-pointer ${
                                             activePage === category.value
                                               ? "bg-sea-green text-white"
                                               : "bg-none"
                                           }`}
                   >
                     <div>
                       <img
                         src={`assets/${category.icon}-${
                           activePage === category.value? "white" : "black"
                         }.svg`}
                         alt={`${category.item}`}
                         className=""
                       />
                     </div>
                     <div>{category.item}</div>
                   </div>
                 );
               })}
   
               <div
                 onClick={() => {
                   setActiveCategory("logout");
                  //  handleLogout();
                  setShowLogoutModal(true);
                 }}
                 className={`flex gap-2 items-center rounded-lg text-red text-xs py-3 cursor-pointer ${
                   currentCategory === "logout" ? "bg-sea-green" : "bg-none"
                 } `}
               >
                 <div>
                   <img src="assets/logout.svg" alt="logout" className="" />
                 </div>
                 <div className="text-red">Log out</div>
               </div>
             </div>
           </div>
         </div>
         }
    


        
        </div>

           
            <div className="">
              <img src="assets/FutrLogo.png" alt="" className="h-16" />
            </div>

            <div className="">
              <button className="btn bg-sea-green px-3 py-2 rounded-md text-white w-full h-full">
                <div className="flex gap-2 justify-evenly items-center w-full h-full">
                  <div className="flex items-center">
                    <img
                      src={
                        thumbnail ? thumbnail : `/assets/image-not-found.png`
                      }
                      alt=""
                      className="rounded-full w-8 h-8 object-cover"
                    />
                  </div>
                  <div className="capitalize truncate text-xs">
                    {name?.split(" ")[0]}
                  </div>

                  <div>{/* <RiArrowDownSLine size={30}/> */}</div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      {
        showLogoutModal && <LogoutConfirmation setShowModal={setShowLogoutModal} logoutHandler={handleLogout} />
      }
    </>
  );
}

export default HeaderMobileMode;