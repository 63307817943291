import React from "react";
import { useState } from "react";

function Pagination({
  currentPage,
  setCurrentPage,
  limit = 15,
  setLimit,
  pageArr = [1, 2, 3, 4, 5],
  setPageArr,
  totalPages,
}) {
  const [open, setOpen] = useState(false);
  const openHandler = () => setOpen((prev) => !prev);
  let limitArr = [15, 30, 45, 60];

  const prevPage = () => {
    if (currentPage > 5) {
      let temp = pageArr.map((item) => item - 5);
      setPageArr(temp);
      setCurrentPage(temp[0]);
    }
  };

  const nextPage = () => {
    if (pageArr[pageArr.length - 1] < totalPages) {
      let temp = pageArr.map((item) => item + 5);
      setPageArr(temp);
      setCurrentPage(temp[0]);
    }
  };
  return (
    <div className="flex items-center justify-between px-8 py-4">
      {/* limit  */}
      <div
        onClick={openHandler}
        className="flex items-center gap-5 cursor-pointer"
      >
        <span className="text-sm font-normal leading-4 text-grey-3">
          Entries per page
        </span>
        {/* limit dropdown */}
        <div className="relative">
          <div className="flex items-center justify-between border border-grey-5 rounded-md px-4 py-2 gap-6">
            <span className="text-md font-semibold">{limit}</span>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99967 15.1874C9.75113 15.1863 9.51285 15.0882 9.33561 14.914L3.08561 8.66398C2.90949 8.48786 2.81055 8.24899 2.81055 7.99992C2.81055 7.75085 2.90949 7.51197 3.08561 7.33585C3.26173 7.15973 3.5006 7.06079 3.74967 7.06079C3.99874 7.06079 4.23762 7.15973 4.41374 7.33585L9.99967 12.9218L15.5856 7.33585C15.7617 7.15973 16.0006 7.06079 16.2497 7.06079C16.4987 7.06079 16.7376 7.15973 16.9137 7.33585C17.0899 7.51197 17.1888 7.75085 17.1888 7.99992C17.1888 8.24899 17.0899 8.48786 16.9137 8.66398L10.6637 14.914C10.4865 15.0882 10.2482 15.1863 9.99967 15.1874Z"
                fill="#666576"
              />
            </svg>
          </div>
          {open && (
            <ul className="w-full absolute bg-white shadow-xl rounded-md overflow-hidden">
              {limitArr?.map((item) => (
                <li
                  key={`limit_${item}`}
                  onClick={() => setLimit(item)}
                  className="px-6 py-1 hover:bg-light-sea-green cursor-pointer"
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {/* pages */}
      <div className="flex items-center">
        <span
          onClick={prevPage}
          className="flex items-center justify-center cursor-pointer border border-grey-8 w-9 h-9"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8128 10.4999C17.8128 10.7486 17.7141 10.987 17.5383 11.1628C17.3624 11.3386 17.124 11.4374 16.8753 11.4374H5.39097L9.41441 15.4609C9.50181 15.548 9.57116 15.6514 9.61848 15.7654C9.66579 15.8794 9.69015 16.0015 9.69015 16.1249C9.69015 16.2483 9.66579 16.3705 9.61848 16.4844C9.57116 16.5984 9.50181 16.7019 9.41441 16.789C9.2375 16.9636 8.99893 17.0615 8.75035 17.0615C8.50177 17.0615 8.2632 16.9636 8.08629 16.789L2.46129 11.164C2.37389 11.0769 2.30454 10.9734 2.25722 10.8594C2.2099 10.7455 2.18555 10.6233 2.18555 10.4999C2.18555 10.3765 2.2099 10.2544 2.25722 10.1404C2.30454 10.0264 2.37389 9.92295 2.46129 9.83586L8.08629 4.21085C8.26241 4.03473 8.50128 3.93579 8.75035 3.93579C8.87368 3.93579 8.9958 3.96008 9.10974 4.00728C9.22368 4.05447 9.32721 4.12365 9.41441 4.21085C9.50162 4.29806 9.57079 4.40159 9.61799 4.51553C9.66518 4.62947 9.68948 4.75159 9.68948 4.87492C9.68948 4.99825 9.66518 5.12037 9.61799 5.23431C9.57079 5.34825 9.50162 5.45177 9.41441 5.53898L5.39097 9.56242H16.8753C17.124 9.56242 17.3624 9.66119 17.5383 9.83701C17.7141 10.0128 17.8128 10.2513 17.8128 10.4999Z"
              fill="#666576"
            />
          </svg>
        </span>
        {pageArr &&
          pageArr.map((item, index) => (
            <>
              {item <= totalPages && (
                <span
                  key={index + 1}
                  onClick={() => {
                    setCurrentPage(item);
                  }}
                  className={`${
                    currentPage == item
                      ? "font-normal bg-aqua-2 text-sea-green"
                      : "font-normal"
                  } w-9 h-9 text-md flex items-center justify-center cursor-pointer border border-grey-8`}
                >
                  {item}
                </span>
              )}
            </>
          ))}
        <span
          onClick={nextPage}
          className="flex items-center justify-center cursor-pointer border border-grey-8 w-9 h-9"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5391 11.164L11.9141 16.789C11.7371 16.9636 11.4986 17.0615 11.25 17.0615C11.0014 17.0615 10.7629 16.9636 10.5859 16.789C10.4985 16.7019 10.4292 16.5984 10.3819 16.4844C10.3346 16.3705 10.3102 16.2483 10.3102 16.1249C10.3102 16.0015 10.3346 15.8794 10.3819 15.7654C10.4292 15.6514 10.4985 15.548 10.5859 15.4609L14.6094 11.4374H3.125C2.87636 11.4374 2.6379 11.3386 2.46209 11.1628C2.28627 10.987 2.1875 10.7486 2.1875 10.4999C2.1875 10.2513 2.28627 10.0128 2.46209 9.83701C2.6379 9.66119 2.87636 9.56242 3.125 9.56242H14.6094L10.5859 5.53898C10.4098 5.36286 10.3109 5.12399 10.3109 4.87492C10.3109 4.75159 10.3352 4.62947 10.3824 4.51553C10.4296 4.40159 10.4987 4.29806 10.5859 4.21085C10.6731 4.12365 10.7767 4.05447 10.8906 4.00728C11.0046 3.96008 11.1267 3.93579 11.25 3.93579C11.4991 3.93579 11.7379 4.03473 11.9141 4.21085L17.5391 9.83586C17.6265 9.92295 17.6958 10.0264 17.7431 10.1404C17.7904 10.2544 17.8148 10.3765 17.8148 10.4999C17.8148 10.6233 17.7904 10.7455 17.7431 10.8594C17.6958 10.9734 17.6265 11.0769 17.5391 11.164Z"
              fill="#666576"
            />
          </svg>
        </span>
      </div>
    </div>
  );
}

export default Pagination;
