import React, { useEffect } from 'react'

const FreeTrial = ({ handler }) => {
    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1000",
    }
      useEffect(() => {
            // Disable scrolling when the modal is open
            document.body.style.overflow = 'hidden';
    
            // Re-enable scrolling when the modal is unmounted
            return () => {
                document.body.style.overflow = 'auto';
            };
        }, []);
    return (
        <div className="modal-wrapper">

            <div className='flex flex-col gap-3 w-10/12 lg:w-1/2' style={modalStyle}>

                <button className='flex justify-end' onClick={handler}>

                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_7613_5695)">
                            <rect width="24" height="24" rx="12" fill="#181717" />
                            <path d="M7.2 17.744L12 12.944L16.8 17.744L17.744 16.8L12.944 12L17.744 7.2L16.8 6.256L12 11.056L7.2 6.256L6.256 7.2L11.056 12L6.256 16.8L7.2 17.744ZM12.004 24C10.3453 24 8.78533 23.6853 7.324 23.056C5.86356 22.4258 4.59289 21.5707 3.512 20.4907C2.43111 19.4116 1.57556 18.1422 0.945333 16.6827C0.315111 15.2231 0 13.6636 0 12.004C0 10.3453 0.314667 8.78533 0.944 7.324C1.57422 5.86356 2.42933 4.59289 3.50933 3.512C4.58844 2.43111 5.85778 1.57556 7.31733 0.945333C8.77689 0.315111 10.3364 0 11.996 0C13.6547 0 15.2147 0.314667 16.676 0.944C18.1364 1.57422 19.4071 2.42933 20.488 3.50933C21.5689 4.58844 22.4244 5.85778 23.0547 7.31733C23.6849 8.77689 24 10.3364 24 11.996C24 13.6547 23.6853 15.2147 23.056 16.676C22.4258 18.1364 21.5707 19.4071 20.4907 20.488C19.4116 21.5689 18.1422 22.4244 16.6827 23.0547C15.2231 23.6849 13.6636 24 12.004 24Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_7613_5695">
                                <rect width="24" height="24" rx="12" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                </button>

                <div className='bg-white flex w-full rounded-2xl box-shadow1 m-auto justify-center p-5'>

                    <div className='flex flex-col items-center gap-5 w-full text-center'>

                        {/* Heading */}

                        <div className='text-sea-green font-semibold text-2xl lg:text-3xl'>
                            Welcome to futr connect!
                        </div>

                        {/* Trophy */}

                        <div>

                            <svg xmlns="http://www.w3.org/2000/svg" width="112" height="136" viewBox="0 0 112 136" fill="none">
                                <path d="M30.4775 56.0721C30.1905 55.99 23.3183 53.9532 12.0394 44.3545C0.383659 34.4349 0.0102907 18.0537 0.000228781 17.3614C-0.00803633 16.7987 0.207934 16.2559 0.600706 15.8527C0.993119 15.4497 1.53017 15.2192 2.09292 15.2129L22.0932 14.9775L22.1428 19.2124L4.40158 19.4214C4.90431 23.894 6.90519 34.424 14.7845 41.1296C25.2532 50.0389 31.5886 51.9844 31.6513 52.0029L30.4775 56.0721Z" fill="#FCAB28" />
                                <path d="M81.523 56.0752L80.3594 52.0031C80.412 51.9873 86.7474 50.0416 97.2161 41.1325C105.095 34.4273 107.096 23.8987 107.599 19.4243L89.8578 19.2154L89.9074 14.9805L109.908 15.2158C110.47 15.2223 111.007 15.4527 111.4 15.8557C111.792 16.259 112.009 16.8016 112 17.3644C111.99 18.0567 111.617 34.4379 99.9612 44.3575C88.6822 53.9565 81.8101 55.9933 81.523 56.0752Z" fill="#FCAB28" />
                                <path d="M21.1774 15.2157C21.1774 15.2157 18.5892 14.2745 18.5892 11.6863C18.5892 9.09813 18.1186 7.92163 20.4717 5.56862C22.8245 3.21562 40.6288 0 54.9819 0C69.335 0 88.9432 3.92155 91.2961 6.03919C93.6491 8.15682 92.7078 13.8038 92.2372 14.7451C91.7667 15.6862 89.8842 15.451 89.8842 15.451L21.1774 15.2157Z" fill="#FFCF3F" />
                                <path d="M54.826 7.91895C45.9662 7.91895 27.2962 9.09545 20.4727 14.0366C20.4727 14.0366 20.4727 39.4484 27.5316 52.3896C34.5905 65.3308 47.0613 75.9189 47.0613 75.9189H53.4143H55.2966H64.0026C64.0026 75.9189 76.4734 65.3308 83.5324 52.3896C90.5913 39.4484 90.5913 14.0366 90.5913 14.0366C83.0616 9.09545 63.6859 7.91895 54.826 7.91895Z" fill="#FFD733" />
                                <path d="M54.826 7.91895C45.9662 7.91895 27.2962 9.09545 20.4727 14.0366C20.4727 14.0366 20.4727 39.4484 27.5316 52.3896C30.8757 58.5206 35.4334 64.1208 39.3054 68.32C42.0142 69.4699 45.1284 70.1941 48.7084 70.2719C70.3558 70.7425 76.003 52.3896 78.3559 45.8013C82.0632 35.4212 84.1727 19.123 85.0014 11.5593C75.9053 8.68328 61.9592 7.91895 54.826 7.91895Z" fill="#FFE266" />
                                <path d="M79.7642 107.685C77.6466 101.332 73.1759 102.274 73.1759 102.274C73.1759 102.274 73.1683 100.231 70.0389 99.9383C68.1002 99.3418 63.1036 97.4229 61.411 93.097C59.2934 87.6853 60.2345 80.8618 60.2345 80.8618L55.5286 80.248L50.8227 80.8618C50.8227 80.8618 51.7639 87.6855 49.6462 93.097C47.9535 97.4227 42.957 99.3418 41.0183 99.9383C37.8889 100.231 37.8813 102.274 37.8813 102.274C37.8813 102.274 33.4106 101.332 31.293 107.685H79.7642Z" fill="#FFCE45" />
                                <path d="M37.8828 102.275C40.8241 102.085 43.7652 101.958 46.7065 101.893C49.6477 101.815 52.5888 101.794 55.5301 101.784C58.4714 101.793 61.4125 101.814 64.3538 101.892C67.2951 101.956 70.2362 102.084 73.1775 102.275C70.2362 102.466 67.2951 102.594 64.3538 102.658C61.4125 102.737 58.4714 102.757 55.5301 102.766C52.5888 102.756 49.6477 102.735 46.7065 102.657C43.7652 102.592 40.8239 102.465 37.8828 102.275Z" fill="#FCAB28" />
                                <path d="M64.0027 79.217H47.2965C46.2569 79.217 45.4141 78.3742 45.4141 77.3346C45.4141 76.295 46.2567 75.4521 47.2965 75.4521H64.0027C65.0423 75.4521 65.8851 76.2948 65.8851 77.3346C65.8851 78.3742 65.0423 79.217 64.0027 79.217Z" fill="#FCAB28" />
                                <path d="M63.0605 81.5895H48.2367C47.3142 81.5895 46.5664 80.8417 46.5664 79.9193C46.5664 78.9968 47.3142 78.249 48.2367 78.249H63.0605C63.983 78.249 64.7308 78.9968 64.7308 79.9193C64.7306 80.8419 63.983 81.5895 63.0605 81.5895Z" fill="#FCAB28" />
                                <path d="M30.6806 107.449H82.0298C83.7692 107.449 85.1791 108.859 85.1791 110.599V132.3C85.1791 134.039 83.769 135.449 82.0298 135.449H30.6806C28.9412 135.449 27.5312 134.039 27.5312 132.3V110.599C27.5313 108.859 28.9413 107.449 30.6806 107.449Z" fill="#6C554F" />
                                <path d="M39.3416 112.295H73.3656C74.1713 112.295 74.8244 112.948 74.8244 113.754V125.613C74.8244 126.418 74.1713 127.071 73.3656 127.071H39.3416C38.5359 127.071 37.8828 126.418 37.8828 125.613V113.754C37.8828 112.948 38.5359 112.295 39.3416 112.295Z" fill="#FCAB28" />
                                <path d="M44.0329 112.295H55.7354C56.0125 112.295 56.2372 112.948 56.2372 113.754V125.613C56.2372 126.418 56.0126 127.071 55.7354 127.071H44.0329C43.7558 127.071 43.5313 126.418 43.5313 125.613V113.754C43.5311 112.948 43.7558 112.295 44.0329 112.295Z" fill="#FFD733" />
                                <path d="M47.0586 75.9242C48.4705 75.8228 49.8822 75.7754 51.2939 75.736C52.7056 75.6911 54.1175 75.6848 55.5293 75.6787C56.941 75.6843 58.3529 75.6907 59.7646 75.7353L61.8822 75.8038C62.5882 75.8446 63.294 75.8685 63.9999 75.9242C63.2941 75.9798 62.5882 76.0037 61.8822 76.0445L59.7646 76.113C58.3529 76.1575 56.941 76.164 55.5293 76.1696C54.1175 76.1635 52.7056 76.1572 51.2939 76.1121C49.882 76.0729 48.4703 76.0255 47.0586 75.9242Z" fill="#FFE266" />
                                <path d="M47.1328 78.7445C48.5708 78.6431 50.0087 78.5957 51.4467 78.5563C52.8846 78.5114 54.3226 78.5051 55.7605 78.499C57.1985 78.5046 58.6364 78.5111 60.0744 78.5556L62.2314 78.6243C62.9503 78.665 63.6691 78.6889 64.3882 78.7446C63.6691 78.8003 62.9503 78.8242 62.2314 78.865L60.0744 78.9335C58.6364 78.978 57.1985 78.9845 55.7605 78.9901C54.3226 78.984 52.8846 78.9775 51.4467 78.9326C50.0087 78.893 48.5708 78.8456 47.1328 78.7445Z" fill="#FFE266" />
                                <path d="M50.9883 81.8812C52.5022 81.688 54.0162 81.6436 55.5303 81.6357C57.0443 81.6426 58.5582 81.6869 60.0722 81.8812C58.5582 82.0754 57.0443 82.1198 55.5303 82.1266C54.0162 82.1189 52.5022 82.0743 50.9883 81.8812Z" fill="#FFE266" />
                                <path d="M32.3125 105.413C36.1818 105.223 40.0512 105.096 43.9205 105.031C47.7898 104.953 51.6591 104.932 55.5285 104.922C59.3978 104.93 63.2671 104.951 67.1365 105.029C71.0058 105.094 74.8751 105.222 78.7444 105.413C74.8749 105.604 71.0058 105.732 67.1365 105.796C63.2671 105.875 59.3978 105.895 55.5285 105.904C51.6591 105.894 47.7898 105.873 43.9205 105.795C40.051 105.73 36.1816 105.603 32.3125 105.413Z" fill="#FCAB28" />
                                <g opacity="0.48">
                                    <path d="M42.931 0.625C38.9782 1.0095 35.146 1.54277 31.7879 2.14989C31.3987 13.769 31.2749 33.1847 34.1161 43.9182C38.3514 59.9182 45.881 67.9183 45.881 67.9183C45.881 67.9183 41.1751 44.389 41.6456 23.683C41.8709 13.7676 42.42 6.01718 42.931 0.625Z" fill="#FFFFFD" />
                                </g>
                                <path d="M90.5913 14.0367C87.9849 12.5449 85.095 11.6916 82.1932 11.0282C79.2857 10.3625 76.334 9.89719 73.3711 9.53443C70.4061 9.18748 67.4316 8.91276 64.4504 8.7356C61.4703 8.55 58.4841 8.42836 55.5016 8.41561C52.5206 8.38668 49.5342 8.48891 46.5534 8.65511C43.5709 8.80873 40.5957 9.07465 37.631 9.41764C34.6691 9.77753 31.7148 10.2264 28.8102 10.9009C25.9136 11.5728 23.0205 12.443 20.4727 14.0367C21.6927 13.1723 23.0106 12.4151 24.4178 11.881C24.7666 11.7405 25.1121 11.5883 25.4646 11.4573L26.5331 11.0951C27.2423 10.8392 27.9661 10.6357 28.691 10.4341C31.5948 9.64134 34.5566 9.08561 37.5311 8.65745C43.4849 7.81136 49.4911 7.42668 55.5074 7.43369C61.5202 7.50178 67.5218 7.92204 73.4727 8.77747C76.4465 9.20832 79.4048 9.78023 82.3097 10.564C83.0392 10.7458 83.7539 10.9853 84.4701 11.2149C85.1913 11.4273 85.8915 11.7074 86.5923 11.9798C87.3023 12.2277 87.9718 12.5786 88.6515 12.8972C88.9958 13.0481 89.3158 13.2468 89.634 13.4474L90.5913 14.0367Z" fill="#FCAB28" />
                                <path d="M40.1816 19.1816L42.3789 15.3311L44.4356 19.2585L48.2865 21.4559L44.3589 23.5127L42.1615 27.3634L40.1047 23.4359L36.2539 21.2385L40.1816 19.1816Z" fill="white" />
                                <g opacity="0.35">
                                    <path d="M33.1211 135.449H41.8413L63.6195 107.449H53.5988L33.1211 135.449Z" fill="white" />
                                </g>
                                <g opacity="0.35">
                                    <path d="M54.6509 135.449L76.2512 107.449H72.1559L50.332 135.449H54.6509Z" fill="white" />
                                </g>
                            </svg>

                        </div>

                        {/* Message */}

                        <div className='text-sea-green text-md lg:text-2xl text-center'>
                        Congratulations, you have unlocked a 3-month free trial. You can view your trial details anytime in the Subscription section. Start exploring today!
                        </div>

                        <div className='w-full flex justify-center'>
                            <button className='bg-sea-green text-white py-2 lg:py-4 w-1/2 rounded-md lg:rounded-xl' onClick={handler}>Get Started</button>
                        </div>


                    </div>

                </div>
            </div>

        </div>
    )
}

export default FreeTrial