import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { toastSuccess } from '../../../utils/Toasters';
import { StateContext } from '../../../Context/StateContext';


export const Success = () => {
  const { setPaymentSuccessLoading } = useContext(StateContext);
    const navigate = useNavigate();
    const ContinueHandler = () => {
      setPaymentSuccessLoading(true)
      setTimeout(() => {
        navigate("/company?activePage=dashboard")
        setPaymentSuccessLoading(false)
      }, 5000);
    }
  return (
    <div className='h-screen flex justify-center'>

    <div className='flex flex-col gap-10 lg:gap-16 items-center m-auto justify-center w-2/3 text-center lg:w-2/5 shadow-xl p-5 h-2/3'>

        <div className='flex flex-col items-center gap-3'>

            <div className='w-1/3'>
               <img src="/assets/Success.svg" className='shadow-md rounded-full'/>
            </div>

            <div className='font-bold text-[32px]'>
            Payment Successful
            </div>
            <div className='text-grey-2 text-xl'>
            Your payment is successful your subscription has started
            </div>


        </div>

        <div className='w-3/4'>
            <button className='bg-sea-green text-white rounded-xl px-5 py-4 w-full' onClick={ContinueHandler}>Continue</button>
        </div>


    </div>
    </div>
  )
}
